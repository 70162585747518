@charset 'UTF-8';

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.nav_mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    background: #fff;
    overflow: auto;
    box-sizing: border-box;
    padding: 40px;
}

.nav_mobile_list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}
    .nav_mobile_list > li {
        display: block;
    }

    .nav_mobile_list > li + li {
        margin-top: 20px;
    }

    .nav_mobile_list > li a {
        display: block;
        text-decoration: none;
        color: #000;
        font-size: 24px;
    }

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close:focus,
.mfp-close:hover {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow:after,
.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

.cont_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 20px;
}

.cont_list>li+li {
    margin-left: 10px;
}

.cont_list a {
    display: block;
    text-decoration: none;
}

.cont_list a img {
    display: block;
    max-width: 50px;
}

.pu_action {
    position: fixed;
    display: flex;
    z-index: 500;
    top: 10%;
    margin-top: -55px;
    right: 0;
    -webkit-transform: translateX(calc(100% - 49px));
       -moz-transform: translateX(calc(100% - 49px));
        -ms-transform: translateX(calc(100% - 49px));
         -o-transform: translateX(calc(100% - 49px));
            transform: translateX(calc(100% - 49px));
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.pu_action__open {
    -webkit-transform: translateX(0%);
       -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
         -o-transform: translateX(0%);
            transform: translateX(0%);
    margin-left: 0;
}

.pu_action__title {
    background: #184D96;
    width: 49px;
    height: 110px;
    padding-top: 9px;
    cursor: pointer;
    -webkit-border-top-left-radius: 3px;
            border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
            border-bottom-left-radius: 3px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .pu_action__title:hover {
        background-color: var(--brown);
    }

    .pu_action__title svg {
        display: block;
        width: 20px;
        margin: 0 auto;
    }

    .pu_action__title span {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 49px;
        color: #FFFFFF;
        margin-top: -26px;
        padding-right: 3px;
        -webkit-transform-origin: right bottom;
           -moz-transform-origin: right bottom;
            -ms-transform-origin: right bottom;
             -o-transform-origin: right bottom;
                transform-origin: right bottom;
        transform: rotate(-90deg);
    }

.pu_action__inner {
    width: 1080px;
    min-width: 1080px;
    padding: 37px 60px 71px 46px;
    background: #FFF;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
}

.pu_action__inner_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

    .pu_action__inner_title span {
        display: block;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 32px;
        line-height: 39px;
    }

    .pu_action__list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 25px;
        margin-left: -32px;
    }
        .pu_action__list > li {
            width: 33.33333333%;
            padding-left: 32px;
            -webkit-box-sizing: border-box;
               -moz-box-sizing: border-box;
                    box-sizing: border-box;
        }

.pu_al__inner {
    position: relative;
    cursor: pointer;
}

    .pu_al__inner img {
        display: block;
        width: 100%;
    }

    .pu_al__inner:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.5);
        -webkit-transition: 0.2s;
           -moz-transition: 0.2s;
            -ms-transition: 0.2s;
             -o-transition: 0.2s;
                transition: 0.2s;
    }

.pu_al__info {
    position: absolute;
    z-index: 20;
    left: 0;
    top: 0;
    padding-left: 12px;
    padding-top: 11px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;
    color: #FFFFFF;
}

.pu_al__name {
    position: absolute;
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.pu_al__podrobno {
    position: absolute;
    z-index: 20;
    left: 12px;
    bottom: 21px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #FFFFFF;
}

    .pu_al__inner:hover .pu_al__podrobno {
        text-decoration: none;
    }

        .pu_al__inner:hover:before {
            opacity: 0.8;
        }

.pu_rgba {
    position: fixed;
    z-index: 99999;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    overflow: auto;
    padding: 20px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.main .pu_rgba {
    position: fixed !important;
    z-index: 99999 !important;
    position: fixed !important;
}

.pu_table {
    display: table;
    width: 100%;
    height: 100%;
}

.pu_cell {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}

.pu_inner {
    position: relative;
    max-width: 500px;
    padding: 83px 40px 100px 40px;
    background: #FFF;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.closeform {
    position: absolute;
    z-index: 20;
    right: 15px;
    top: 15px;
    cursor: pointer;
    padding: 5px;
}

    .closeform svg {
        display: block;
        width: 22px;
    }

    .closeform:hover line {
        stroke: #a70909;
    }

.pu_title {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #000000;
}

.pu_form {
    display: block;
    margin-top: 55px;
}

.in_style {
    display: block;
    position: relative;
    border-bottom: 1px solid #000;
}

    .in_style input {
        display: block;
        width: 100%;
        bottom: 40px;
        height: 40px;
        background: none;
        margin: 0;
        padding: 0;
        border: none;
        outline-style: none;
        font-size: 16px;
        color: #000;
        text-transform: uppercase;
        padding-left: 35px;
    }

    .in_style i {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-justify-content: center;
                justify-content: center;
        width: 20px;
        height: 40px;
        -webkit-align-items: center;
                align-items: center;
    }

        .in_style i svg {
            display: block;
        }

    .in_style + .in_style {
        margin-top: 28px;
    }

.in_name {
    position: absolute;
    left: 35px;
    line-height: 16px;
    top: 12px;
    display: block;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.in_style__act .in_name {
    top: -14px;
    text-transform: none;
}

.in_style__act i path {
    stroke: #184D96;
}

.align_center {
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.pu_inner .align_center {
    margin-top: 43px;
}

.pu_btn {
    cursor: pointer;
    outline-style: none;
    text-decoration: none;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    text-align: center;
    font-size: 16px;
    color: #FFF;
    height: 45px;
    display: inline-flex;
    background: #11366A;
    text-transform: uppercase;
    padding-left: 34px;
    padding-right: 34px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    line-height: 45px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .pu_btn:hover {
        background: #E39152;
    }

.main, .banner_action, section, .footer {
    overflow: hidden;
}

.popup_action__title {
    font-size: 52px;
    line-height: 100%;
    font-family: Montserrat;
    text-align: center;
    font-weight: 800;
    color: #184D96;
}

.pu_action__list2 {
    display: flex;
    margin-left: -25px;
    margin-top: 48px;
}

    .pu_action__list2 > li {
        width: 33.3333333%;
        padding-left: 25px;
        cursor: pointer;
        -webkit-transition: 0.2s;
           -moz-transition: 0.2s;
            -ms-transition: 0.2s;
             -o-transition: 0.2s;
                transition: 0.2s;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .pu_action__list2 > li:hover {
        -webkit-transform: scale(1.03);
           -moz-transform: scale(1.03);
            -ms-transform: scale(1.03);
             -o-transform: scale(1.03);
                transform: scale(1.03);
    }

    .pu_action__list2 > li img {
        display: block;
        width: 100%;
    }

.pu_al__info2 {
    display: flex;
    width: 100%;
    height: 63px;
    color: #184D96;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    background: #E0833C;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.pu_al__name2 {
    height: 50px;
    display: flex;
    width: 100%;
    background: #184D96;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.sel_months {
    display: block;
    width: 100%;
    font-size: 22px;
    height: 70px;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.progress {
    height: 1000px;
}

.left-menu {
    z-index: 222 !important;
}

.header_main_m {
    position: relative;
}




@media all and (max-height:680px) {
    .pu_action {
        top: auto;
        bottom: -360px;
        margin-top: 0;
    }

    .pu_action__open {
        bottom: 10px;
    }
} 

@media all and (max-width:640px) {
    .nav_mobile_list > li a {
        font-size: 18px;
    }
    .nav_mobile_list > li + li {
        margin-top: 13px;
    }
}

@media screen and (max-width:800px) and (orientation:landscape), screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

@media screen and (max-width:380px) {
    .nav_mobile {
        padding-left: 20px;
        padding-right: 20px;
    }
    .cont_list a img {
        max-width: 40px;
    }
}

.owl-carousel,
.owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.owl-carousel .animated {
    animation-duration: 1s;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    animation-name: fadeOut
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
    max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(../libs/owl/dist/assets/owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav {
    margin-top: 10px
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
    transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
    transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
    transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
    transition-duration: .15s
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
    transition-delay: .15s
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
    transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
    transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
    transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
    transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
    transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
    transition-duration: .35s
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
    transition-delay: .35s
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
    transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
    transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
    transition-duration: .45s
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
    transition-delay: .45s
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
    transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
    transition-duration: .55s
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
    transition-delay: .55s
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
    transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
    transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
    transition-duration: .65s
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
    transition-delay: .65s
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
    transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
    transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
    transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
    transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
    transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
    transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
    transition-duration: .85s
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
    transition-delay: .85s
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
    transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
    transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
    transition-duration: .95s
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
    transition-delay: .95s
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
    transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
    transition-duration: 1.05s
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
    transition-delay: 1.05s
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
    transition-duration: 1.1s
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
    transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
    transition-duration: 1.15s
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
    transition-delay: 1.15s
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
    transition-duration: 1.2s
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
    transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
    transition-duration: 1.25s
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
    transition-delay: 1.25s
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
    transition-duration: 1.3s
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
    transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
    transition-duration: 1.35s
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
    transition-delay: 1.35s
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
    transition-duration: 1.4s
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
    transition-delay: 1.4s
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
    transition-duration: 1.45s
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
    transition-delay: 1.45s
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
    transition-duration: 1.5s
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
    transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
    transition-duration: 1.55s
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
    transition-delay: 1.55s
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
    transition-duration: 1.6s
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
    transition-delay: 1.6s
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
    transition-duration: 1.65s
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
    transition-delay: 1.65s
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
    transition-duration: 1.7s
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
    transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
    transition-duration: 1.75s
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
    transition-delay: 1.75s
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
    transition-duration: 1.8s
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
    transition-delay: 1.8s
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
    transition-duration: 1.85s
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
    transition-delay: 1.85s
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
    transition-duration: 1.9s
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
    transition-delay: 1.9s
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
    transition-duration: 1.95s
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
    transition-delay: 1.95s
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
    transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
    transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
    transition-duration: 2.05s
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
    transition-delay: 2.05s
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
    transition-duration: 2.1s
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
    transition-delay: 2.1s
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
    transition-duration: 2.15s
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
    transition-delay: 2.15s
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
    transition-duration: 2.2s
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
    transition-delay: 2.2s
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
    transition-duration: 2.25s
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
    transition-delay: 2.25s
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
    transition-duration: 2.3s
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
    transition-delay: 2.3s
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
    transition-duration: 2.35s
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
    transition-delay: 2.35s
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
    transition-duration: 2.4s
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
    transition-delay: 2.4s
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
    transition-duration: 2.45s
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
    transition-delay: 2.45s
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
    transition-duration: 2.5s
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
    transition-delay: 2.5s
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
    transition-duration: 2.55s
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
    transition-delay: 2.55s
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
    transition-duration: 2.6s
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
    transition-delay: 2.6s
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
    transition-duration: 2.65s
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
    transition-delay: 2.65s
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
    transition-duration: 2.7s
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
    transition-delay: 2.7s
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
    transition-duration: 2.75s
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
    transition-delay: 2.75s
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
    transition-duration: 2.8s
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
    transition-delay: 2.8s
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
    transition-duration: 2.85s
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
    transition-delay: 2.85s
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
    transition-duration: 2.9s
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
    transition-delay: 2.9s
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
    transition-duration: 2.95s
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
    transition-delay: 2.95s
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
    transition-duration: 3s
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
    transition-delay: 3s
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
    transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
    transition-timing-function: ease-out
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
    transition-timing-function: ease-in-out
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0)
}

[data-aos=fade-up] {
    transform: translate3d(0, 100px, 0)
}

[data-aos=fade-down] {
    transform: translate3d(0, -100px, 0)
}

[data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0)
}

[data-aos=fade-left] {
    transform: translate3d(100px, 0, 0)
}

[data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0)
}

[data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0)
}

[data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0)
}

[data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0)
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1)
}

[data-aos=zoom-in] {
    transform: scale(.6)
}

[data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(.6)
}

[data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(.6)
}

[data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(.6)
}

[data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(.6)
}

[data-aos=zoom-out] {
    transform: scale(1.2)
}

[data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2)
}

[data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2)
}

[data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
    transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translateZ(0)
}

[data-aos=slide-up] {
    transform: translate3d(0, 100%, 0)
}

[data-aos=slide-down] {
    transform: translate3d(0, -100%, 0)
}

[data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0)
}

[data-aos=slide-left] {
    transform: translate3d(100%, 0, 0)
}

[data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform
}

[data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0)
}

.slider-container {
    width: 300px
}

.slider-container .back-bar {
    height: 10px;
    position: relative
}

.slider-container .back-bar .selected-bar {
    position: absolute;
    height: 100%
}

.slider-container .back-bar .pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    cursor: col-resize;
    opacity: 1;
    z-index: 2
}

.slider-container .back-bar .pointer.last-active {
    z-index: 3
}

.slider-container .back-bar .pointer-label {
    position: absolute;
    top: -17px;
    font-size: 8px;
    background: #fff;
    white-space: nowrap;
    line-height: 1
}

.slider-container .back-bar .focused {
    z-index: 10
}

.slider-container .clickable-dummy {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1
}

.slider-container .scale {
    top: 2px;
    position: relative
}

.slider-container .scale span {
    position: absolute;
    height: 5px;
    border-left: 1px solid #999;
    font-size: 0
}

.slider-container .scale ins {
    font-size: 9px;
    text-decoration: none;
    position: absolute;
    left: 0;
    top: 5px;
    color: #999;
    line-height: 1
}

.slider-container.slider-readonly .clickable-dummy,
.slider-container.slider-readonly .pointer {
    cursor: auto
}

.theme-green .back-bar {
    height: 5px;
    border-radius: 2px;
    background-color: #eee;
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #eee, #ddd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eee), to(#ddd));
    background-image: -webkit-linear-gradient(top, #eee, #ddd);
    background-image: -o-linear-gradient(top, #eee, #ddd);
    background-image: linear-gradient(to bottom, #eee, #ddd);
    background-repeat: repeat-x
}

.theme-green .back-bar .selected-bar {
    border-radius: 2px;
    background-color: #a1fad0;
    background-image: -moz-linear-gradient(top, #bdfade, #76fabc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bdfade), to(#76fabc));
    background-image: -webkit-linear-gradient(top, #bdfade, #76fabc);
    background-image: -o-linear-gradient(top, #bdfade, #76fabc);
    background-image: linear-gradient(to bottom, #bdfade, #76fabc);
    background-repeat: repeat-x
}

.theme-green .back-bar .pointer {
    width: 14px;
    height: 14px;
    top: -5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #aaa;
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #eee, #ddd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eee), to(#ddd));
    background-image: -webkit-linear-gradient(top, #eee, #ddd);
    background-image: -o-linear-gradient(top, #eee, #ddd);
    background-image: linear-gradient(to bottom, #eee, #ddd);
    background-repeat: repeat-x
}

.theme-green .back-bar .pointer-label {
    color: #999
}

.theme-green .back-bar .focused {
    color: #333
}

.theme-green .scale span {
    border-left: 1px solid #e5e5e5
}

.theme-green .scale ins {
    color: #999
}

.theme-blue .back-bar {
    height: 5px;
    border-radius: 2px;
    background-color: #eee;
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #eee, #ddd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eee), to(#ddd));
    background-image: -webkit-linear-gradient(top, #eee, #ddd);
    background-image: -o-linear-gradient(top, #eee, #ddd);
    background-image: linear-gradient(to bottom, #eee, #ddd);
    background-repeat: repeat-x
}

.theme-blue .back-bar .selected-bar {
    border-radius: 2px;
    background-color: #92c1f9;
    background-image: -moz-linear-gradient(top, #b1d1f9, #64a8f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b1d1f9), to(#64a8f9));
    background-image: -webkit-linear-gradient(top, #b1d1f9, #64a8f9);
    background-image: -o-linear-gradient(top, #b1d1f9, #64a8f9);
    background-image: linear-gradient(to bottom, #b1d1f9, #64a8f9);
    background-repeat: repeat-x
}

.theme-blue .back-bar .pointer {
    width: 14px;
    height: 14px;
    top: -5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #aaa;
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #eee, #ddd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eee), to(#ddd));
    background-image: -webkit-linear-gradient(top, #eee, #ddd);
    background-image: -o-linear-gradient(top, #eee, #ddd);
    background-image: linear-gradient(to bottom, #eee, #ddd);
    background-repeat: repeat-x
}

.theme-blue .back-bar .pointer-label {
    color: #999
}

.theme-blue .back-bar .focused {
    color: #333
}

.theme-blue .scale span {
    border-left: 1px solid #e5e5e5
}

.theme-blue .scale ins {
    color: #999
}

@font-face {
    font-family: lg;
    src: url(../libs/gallery/dist/fonts/lg.ttf?22t19m) format("truetype"), url(../libs/gallery/dist/fonts/lg.woff?22t19m) format("woff"), url(../libs/gallery/dist/fonts/lg.svg?22t19m#lg) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
}

.lg-icon {
    font-family: lg !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background-color: rgba(0, 0, 0, .45);
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1080;
    border: none;
    outline: 0
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: .5
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
    color: #fff
}

.lg-actions .lg-next {
    right: 20px
}

.lg-actions .lg-next:before {
    content: "\e095"
}

.lg-actions .lg-prev {
    left: 20px
}

.lg-actions .lg-prev:after {
    content: "\e094"
}

@-webkit-keyframes lg-right-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: -30px
    }
}

@-moz-keyframes lg-right-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: -30px
    }
}

@-ms-keyframes lg-right-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: -30px
    }
}

@keyframes lg-right-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: -30px
    }
}

@-webkit-keyframes lg-left-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: 30px
    }
}

@-moz-keyframes lg-left-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: 30px
    }
}

@-ms-keyframes lg-left-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: 30px
    }
}

@keyframes lg-left-end {

    0%,
    100% {
        left: 0
    }

    50% {
        left: 30px
    }
}

.lg-outer.lg-right-end .lg-object {
    -webkit-animation: lg-right-end .3s;
    -o-animation: lg-right-end .3s;
    animation: lg-right-end .3s;
    position: relative
}

.lg-outer.lg-left-end .lg-object {
    -webkit-animation: lg-left-end .3s;
    -o-animation: lg-left-end .3s;
    animation: lg-left-end .3s;
    position: relative
}

.lg-toolbar {
    z-index: 1082;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .45)
}

.lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: 0;
    background: 0 0;
    border: none;
    box-shadow: none;
    -webkit-transition: color .2s linear;
    -o-transition: color .2s linear;
    transition: color .2s linear
}

.lg-toolbar .lg-icon:hover {
    color: #fff
}

.lg-toolbar .lg-close:after {
    content: "\e070"
}

.lg-toolbar .lg-download:after {
    content: "\e0f2"
}

.lg-sub-html {
    background-color: rgba(0, 0, 0, .45);
    bottom: 0;
    color: #eee;
    font-size: 16px;
    left: 0;
    padding: 10px 40px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 1080
}

.lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 700
}

.lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0
}

#lg-counter {
    color: #999;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle
}

.lg-next,
.lg-prev,
.lg-toolbar {
    opacity: 1;
    -webkit-transition: -webkit-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    -moz-transition: -moz-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    -o-transition: -o-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    transition: transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear
}

.lg-hide-items .lg-prev {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
}

.lg-hide-items .lg-next {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
}

.lg-hide-items .lg-toolbar {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
    opacity: 0;
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -o-transition: -o-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1
}

.lg-outer .lg-thumb-outer {
    background-color: #0d0a0a;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1080;
    max-height: 350px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform .25s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.lg-outer .lg-thumb {
    padding: 10px 0;
    height: 100%;
    margin-bottom: -5px
}

.lg-outer .lg-thumb-item {
    cursor: pointer;
    float: left;
    overflow: hidden;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-bottom: 5px
}

@media (min-width:1025px) {
    .lg-outer .lg-thumb-item {
        -webkit-transition: border-color .25s ease;
        -o-transition: border-color .25s ease;
        transition: border-color .25s ease
    }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: #a90707
}

.lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.lg-outer.lg-has-thumb .lg-item {
    padding-bottom: 120px
}

.lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
    -webkit-transition: bottom .25s ease;
    -o-transition: bottom .25s ease;
    transition: bottom .25s ease
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
    bottom: 100px
}

.lg-outer .lg-toogle-thumb {
    background-color: #0d0a0a;
    border-radius: 2px 2px 0 0;
    color: #999;
    cursor: pointer;
    font-size: 24px;
    height: 39px;
    line-height: 27px;
    padding: 5px 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -39px;
    width: 50px;
    outline: 0;
    border: none
}

.lg-outer .lg-toogle-thumb:after {
    content: "\e1ff"
}

.lg-outer .lg-toogle-thumb:hover {
    color: #fff
}

.lg-outer .lg-video-cont {
    display: inline-block;
    vertical-align: middle;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 0 5px
}

.lg-outer .lg-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative
}

.lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important
}

.lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer
}

.lg-outer .lg-has-iframe .lg-video {
    -webkit-overflow-scrolling: touch;
    overflow: auto
}

.lg-outer .lg-has-vimeo .lg-video-play {
    background: url(../libs/gallery/dist/img/vimeo-play.png) no-repeat
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
    background: url(../libs/gallery/dist/img/vimeo-play.png) 0 -58px no-repeat
}

.lg-outer .lg-has-html5 .lg-video-play {
    background: url(../libs/gallery/dist/img/video-play.png) no-repeat;
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    opacity: .8
}

.lg-outer .lg-has-html5:hover .lg-video-play {
    opacity: 1
}

.lg-outer .lg-has-youtube .lg-video-play {
    background: url(../libs/gallery/dist/img/youtube-play.png) no-repeat
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
    background: url(../libs/gallery/dist/img/youtube-play.png) 0 -60px no-repeat
}

.lg-outer .lg-video-object {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0
}

.lg-outer .lg-has-video .lg-video-object {
    visibility: hidden
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
    display: none
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
    visibility: visible
}

.lg-progress-bar {
    background-color: #333;
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1083;
    opacity: 0;
    -webkit-transition: opacity 80ms ease 0s;
    -moz-transition: opacity 80ms ease 0s;
    -o-transition: opacity 80ms ease 0s;
    transition: opacity 80ms ease 0s
}

.lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0
}

.lg-progress-bar.lg-start .lg-progress {
    width: 100%
}

.lg-show-autoplay .lg-progress-bar {
    opacity: 1
}

.lg-autoplay-button:after {
    content: "\e01d"
}

.lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
    -moz-transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
    -o-transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
    transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

#lg-zoom-in:after {
    content: "\e311"
}

#lg-actual-size {
    font-size: 20px
}

#lg-actual-size:after {
    content: "\e033"
}

#lg-zoom-out {
    opacity: .5;
    pointer-events: none
}

#lg-zoom-out:after {
    content: "\e312"
}

.lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto
}

.lg-outer .lg-pager-outer {
    bottom: 60px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1080;
    height: 10px
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible
}

.lg-outer .lg-pager-cont {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    margin: 0 5px
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px #fff inset
}

.lg-outer .lg-pager-thumb-cont {
    background-color: #fff;
    color: #fff;
    bottom: 100%;
    height: 83px;
    left: 0;
    margin-bottom: 20px;
    margin-left: -60px;
    opacity: 0;
    padding: 5px;
    position: absolute;
    width: 120px;
    border-radius: 3px;
    -webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
    -moz-transition: opacity .15s ease 0s, -moz-transform .15s ease 0s;
    -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
    transition: opacity .15s ease 0s, transform .15s ease 0s;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
}

.lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%
}

.lg-outer .lg-pager {
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, .7) inset;
    display: block;
    height: 12px;
    -webkit-transition: box-shadow .3s ease 0s;
    -o-transition: box-shadow .3s ease 0s;
    transition: box-shadow .3s ease 0s;
    width: 12px
}

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
    box-shadow: 0 0 0 8px #fff inset
}

.lg-outer .lg-caret {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px dashed;
    bottom: -10px;
    display: inline-block;
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    vertical-align: middle;
    width: 0
}

.lg-fullscreen:after {
    content: "\e20c"
}

.lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"
}

.lg-outer #lg-dropdown-overlay {
    background-color: rgba(0, 0, 0, .25);
    bottom: 0;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1081;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s linear .18s, opacity .18s linear 0s;
    -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
    transition: visibility 0s linear .18s, opacity .18s linear 0s
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible
}

.lg-outer.lg-dropdown-active #lg-share {
    color: #fff
}

.lg-outer .lg-dropdown {
    background-color: #fff;
    border-radius: 2px;
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50px;
    opacity: 0;
    visibility: hidden;
    -moz-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
    -webkit-transition: -webkit-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
    -moz-transition: -moz-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
    -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
    transition: transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s
}

.lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    right: 16px;
    top: -16px
}

.lg-outer .lg-dropdown>li:last-child {
    margin-bottom: 0
}

.lg-outer .lg-dropdown>li:hover .lg-icon,
.lg-outer .lg-dropdown>li:hover a {
    color: #333
}

.lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px
}

.lg-outer .lg-dropdown a:hover {
    background-color: rgba(0, 0, 0, .07)
}

.lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle
}

.lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
    height: 100%;
    width: 100%
}

.lg-outer #lg-share {
    position: relative
}

.lg-outer #lg-share:after {
    content: "\e80d"
}

.lg-outer #lg-share-facebook .lg-icon {
    color: #3b5998
}

.lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e904"
}

.lg-outer #lg-share-twitter .lg-icon {
    color: #00aced
}

.lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e907"
}

.lg-outer #lg-share-googleplus .lg-icon {
    color: #dd4b39
}

.lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e905"
}

.lg-outer #lg-share-pinterest .lg-icon {
    color: #cb2027
}

.lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e906"
}

.lg-outer .lg-img-rotate {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: -webkit-transform .3s cubic-bezier(.32, 0, .67, 0) 0s;
    -moz-transition: -moz-transform .3s cubic-bezier(.32, 0, .67, 0) 0s;
    -o-transition: -o-transform .3s cubic-bezier(.32, 0, .67, 0) 0s;
    transition: transform .3s cubic-bezier(.32, 0, .67, 0) 0s
}

.lg-rotate-left:after {
    content: "\e900"
}

.lg-rotate-right:after {
    content: "\e901"
}

.lg-icon.lg-flip-hor,
.lg-icon.lg-flip-ver {
    font-size: 26px
}

.lg-flip-ver:after {
    content: "\e903"
}

.lg-flip-hor:after {
    content: "\e902"
}

.lg-group:after {
    content: "";
    display: table;
    clear: both
}

.lg-outer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    text-align: left;
    opacity: 0;
    outline: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s
}

.lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.lg-outer.lg-visible {
    opacity: 1
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1
}

.lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing
}

.lg-outer .lg {
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%
}

.lg-outer .lg-inner {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap
}

.lg-outer .lg-item {
    background: url(../libs/gallery/dist/img/loading.gif) center center no-repeat;
    display: none !important
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
    display: inline-block !important
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px
}

.lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.lg-outer .lg-item.lg-complete {
    background-image: none
}

.lg-outer .lg-item.lg-current {
    z-index: 1060
}

.lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
    display: none
}

.lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s
}

.lg-backdrop.in {
    opacity: 1
}

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
    -webkit-transition: none 0s ease 0s !important;
    -moz-transition: none 0s ease 0s !important;
    -o-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-css3.lg-fade .lg-item {
    opacity: 0
}

.lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1
}

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
    -webkit-transition: opacity .1s ease 0s;
    -moz-transition: opacity .1s ease 0s;
    -o-transition: opacity .1s ease 0s;
    transition: opacity .1s ease 0s
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s
}

.lg-css3.lg-slide.lg-use-left .lg-item {
    opacity: 0;
    position: absolute;
    left: 0
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    -webkit-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slick-loading .slick-list {
    background: #fff url(../libs/slick/slick/ajax-loader.gif) center center no-repeat
}

@font-face {
    font-family: slick;
    font-weight: 400;
    font-style: normal;
    src: url(../libs/slick/slick/fonts/slick.eot);
    src: url(../libs/slick/slick/fonts/slick.eot?#iefix) format('embedded-opentype'), url(../libs/slick/slick/fonts/slick.woff) format('woff'), url(../libs/slick/slick/fonts/slick.ttf) format('truetype'), url(../libs/slick/slick/fonts/slick.svg#slick) format('svg')
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25
}

.slick-next:before,
.slick-prev:before {
    font-family: slick;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-prev {
    left: -25px
}

[dir=rtl] .slick-prev {
    right: -25px;
    left: auto
}

.slick-prev:before {
    content: 'в†ђ'
}

[dir=rtl] .slick-prev:before {
    content: 'в†’'
}

.slick-next {
    right: -25px
}

[dir=rtl] .slick-next {
    right: auto;
    left: -25px
}

.slick-next:before {
    content: 'в†’'
}

[dir=rtl] .slick-next:before {
    content: 'в†ђ'
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1
}

.slick-dots li button:before {
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: 'вЂў';
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000
}

@font-face {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/Montserrat/Montserrat-Regular.eot);
    src: url(../fonts/Montserrat/Montserrat-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Montserrat/Montserrat-Regular.woff) format("woff"), url(../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-weight: 500;
    font-style: normal;
    src: url(../fonts/Montserrat/Montserrat-Medium.eot);
    src: url(../fonts/Montserrat/Montserrat-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/Montserrat/Montserrat-Medium.woff) format("woff"), url(../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
    src: url(../fonts/Montserrat/Montserrat-Bold.eot);
    src: url(../fonts/Montserrat/Montserrat-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/Montserrat/Montserrat-Bold.woff) format("woff"), url(../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-weight: 900;
    font-style: normal;
    src: url(../fonts/Montserrat/Montserrat-Black.eot);
    src: url(../fonts/Montserrat/Montserrat-Black.eot?#iefix) format("embedded-opentype"), url(../fonts/Montserrat/Montserrat-Black.woff) format("woff"), url(../fonts/Montserrat/Montserrat-Black.ttf) format("truetype")
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

@-ms-viewport {
    width: device-width
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar
}

*,
::after,
::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 100%
    }
}

@media (min-width:768px) {
    .container {
        max-width: 100%
    }
}

@media (min-width:992px) {
    .container {
        max-width: 100%
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

@media (min-width:1400px) {
    .container {
        max-width: 1300px
    }
}

@media (min-width:1920px) {
    .container {
        max-width: 1750px
    }
}

@media (max-width:1919px) {
    .apps .owl-nav, .cards .owl-nav, .progress .owl-nav {
        margin-top: 0;
    }
    .progress {
        height: 740px;
    }
}

@media (max-width:1399px) {
    .progress {
        height: 640px;
    }
}

.progress {
    padding-top: 20px;
}

.sel_months + .sel_months {
    margin-top: 15px;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto,
.col-xxxl,
.col-xxxl-1,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1400px) {
    .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xxl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xxl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xxl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xxl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xxl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xxl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xxl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxl-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1920px) {
    .col-xxxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xxxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xxxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xxxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xxxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xxxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xxxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xxxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xxxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xxxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xxxl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xxxl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13
    }

    .order-xxxl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .order-xxxl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xxxl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xxxl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xxxl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xxxl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xxxl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xxxl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xxxl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xxxl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xxxl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xxxl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xxxl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xxxl-0 {
        margin-left: 0
    }

    .offset-xxxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxxl-3 {
        margin-left: 25%
    }

    .offset-xxxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxxl-6 {
        margin-left: 50%
    }

    .offset-xxxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxxl-9 {
        margin-left: 75%
    }

    .offset-xxxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxxl-11 {
        margin-left: 91.66667%
    }
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1400px) {
    .d-xxl-none {
        display: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1920px) {
    .d-xxxl-none {
        display: none !important
    }

    .d-xxxl-inline {
        display: inline !important
    }

    .d-xxxl-inline-block {
        display: inline-block !important
    }

    .d-xxxl-block {
        display: block !important
    }

    .d-xxxl-table {
        display: table !important
    }

    .d-xxxl-table-row {
        display: table-row !important
    }

    .d-xxxl-table-cell {
        display: table-cell !important
    }

    .d-xxxl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xxxl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .header__right  {
        display: flex !important;
        align-items: center !important;
        flex-direction: row !important;
    }
    .header__phone {
        margin-bottom: 0 !important;
        margin-left: 20px;
    }
}

@media (min-width:1400px) {
    .flex-xxl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xxl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:1920px) {
    .flex-xxxl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xxxl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xxxl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xxxl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xxxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xxxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xxxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxxl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xxxl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xxxl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xxxl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xxxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xxxl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xxxl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xxxl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xxxl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xxxl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xxxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xxxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xxxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xxxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xxxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xxxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xxxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xxxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xxxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xxxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xxxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xxxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

:root {
    --yellow: #F6CC39;
    --light-yellow: #f7d251;
    --brown: #E0833C;
    --light-brown: #e39152;
    --light-blue: #66BDEA;
    --blue: #184D96;
    --gray: #F1F1F1;
    --dark-brown: #c96920;
    --dark-blue: #11366a;
    --red: #C4122F;
    --dark-red: #a70909
}

::-webkit-input-placeholder {
    color: #333;
    opacity: .5
}

::-moz-placeholder {
    color: #333;
    opacity: .5
}

:-ms-input-placeholder {
    color: #333;
    opacity: .5
}

::-ms-input-placeholder {
    color: #333;
    opacity: .5
}

::placeholder {
    color: #333;
    opacity: .5
}

::-moz-selection {
    background-color: var(--yellow);
    color: #303030
}

::selection {
    background-color: var(--yellow);
    color: #303030
}

html {
    overflow-x: hidden
}

body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: 1.65;
    font-family: Montserrat, sans-serif;
    color: #303030;
    overflow-x: hidden
}

body.on {
    overflow: hidden
}

body input,
body textarea {
    border: #666 1px solid;
    outline: 0
}

body input,
body select,
body textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0
}

body select {
    background-image: url(../img/selectarrow.svg);
    background-position: right 10px top 50%, 0 0;
    background-size: 20px 10px;
    background-repeat: no-repeat
}

body select:focus:required:invalid {
    border-color: red
}

body select:required:valid {
    border-color: green
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-responsive.img-width {
    width: 100%
}

.text-center {
    text-align: center
}

b,
strong {
    font-weight: 700
}

a {
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

a:active,
a:hover {
    text-decoration: none;
    color: var(--blue)
}

.z-index-container {
    position: relative;
    z-index: 2
}

.main {
    height: 100vh;
    min-height: 520px;
    position: relative;
    color: #fff
}

.head_bankdom {
    display: block;
    text-decoration: none;
    outline-style: none;
    position: absolute;
    z-index: 50;
    right: 100px;
    bottom: 0;
}

    .head_bankdom img {
        display: block;
        max-width: 100%;
    }

.main>div {
    position: relative;
    z-index: 2
}

.main:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    z-index: 2
}

.main .btn {
    margin-top: 20px;
}

.main .container,
.main .row {
    height: 100%
}

.main__description,
.main__h1,
.main__h2 {
    text-align: center;
    line-height: 1.3
}

.main__h1 {
    margin: 10px 0;
    padding: 0;
    font-size: 2.1875rem;
    font-weight: 900
}

.main__description,
.main__h2 {
    margin: 0;
    padding: 0
}

.main__description {
    font-size: 1rem;
    font-weight: 300
}

.main__h2 {
    font-size: 1.5625rem;
    margin-bottom: 30px
}

.main__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center
}

.main__col-center {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 520px;
}

.main__content {
    margin: auto 0
}

.h2 {
    margin-bottom: 30px
}

.h2__caption {
    font-size: 1.5625rem;
    font-weight: 700;
    text-transform: uppercase
}

.h2__img {
    margin-top: 15px
}

.blue-caption {
    margin-bottom: 30px
}

.blue-caption__whitebox {
    font-size: 1.125rem;
    display: block;
    color: #303030
}

.blue-caption__whitebox:hover {
    opacity: .8;
    color: #303030
}

.blue-caption__whitebox span {
    position: relative;
    text-decoration: underline
}

.blue-caption__whitebox span:before {
    background-size: cover;
    width: 7px;
    height: 11px;
    position: absolute;
    content: '';
    right: -12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url(../img/whitebox-arrow.svg)
}

.blue-caption__description {
    font-weight: 700;
    font-size: 1.125rem
}

.blue-caption__h2 {
    font-size: 1.5625rem;
    color: #fff;
    background-color: var(--light-blue);
    display: inline-block;
    padding: 10px 30px 10px 0;
    position: relative;
    text-transform: uppercase
}

.blue-caption__h2 span {
    display: block
}

.blue-caption__h2:before {
    content: '';
    top: 0;
    right: 0;
    min-width: 500px;
    width: 50vw;
    position: absolute;
    height: 100%;
    background-color: var(--light-blue)
}

.caption {
    margin-bottom: 30px
}

.caption__whitebox {
    font-size: 1.125rem;
    display: block;
    color: #303030
}

.caption__whitebox:hover {
    opacity: .8;
    color: #303030
}

.caption__whitebox span {
    position: relative;
    text-decoration: underline
}

.caption__whitebox span:before {
    background-size: cover;
    width: 7px;
    height: 11px;
    position: absolute;
    content: '';
    right: -12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url(../img/whitebox-arrow.svg)
}

.caption__description {
    font-weight: 700;
    font-size: 1.125rem
}

.caption__h2 {
    font-size: 1.5625rem;
    color: #fff;
    background-color: var(--light-blue);
    display: inline-block;
    padding: 10px 30px 10px 0;
    position: relative;
    text-transform: uppercase
}

.caption__h2 span {
    display: block
}

.caption__h2:before {
    content: '';
    top: 0;
    right: 0;
    min-width: 500px;
    width: 50vw;
    position: absolute;
    height: 100%;
    background-color: var(--light-blue)
}

.caption__h2--red:before {
    background-color: var(--red)
}

.caption__h2--brown:before {
    background-color: var(--brown)
}

.caption__h2--yellow:before {
    background-color: var(--yellow)
}

.caption__h2--blue:before {
    background-color: var(--blue)
}

.caption__h2--light-blue:before {
    background-color: var(--light-blue)
}

.scroll {
    text-align: center;
    position: relative;
    -webkit-filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .21));
    filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .21));
    display: block
}

.scroll__arrow {
    height: 60px
}

.scroll__anim {
    position: relative;
    overflow: hidden
}

.scroll__anim:before {
    content: '';
    top: 0;
    left: 50%;
    width: 1px;
    height: 30px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: var(--brown);
    position: absolute;
    -webkit-animation: scrollArrow 2s infinite ease-in-out;
    animation: scrollArrow 2s infinite ease-in-out
}

@-webkit-keyframes scrollArrow {
    0% {
        opacity: 0;
        top: -100%
    }

    100% {
        opacity: 1;
        top: 100%
    }
}

@keyframes scrollArrow {
    0% {
        opacity: 0;
        top: -100%
    }

    100% {
        opacity: 1;
        top: 100%
    }
}

.logo__img {
    max-height: 25px;
    max-width: 120px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left center;
    object-position: left center
}

.header {
    padding: 10px 0;
    position: fixed;
    z-index: 200;
    width: 100%;
    background: rgba(0,0,0,0.4);
}

.header--page404 {
    background-color: #ccc;
    color: #303030
}

.header:before {
    content: '';
    position: absolute;
    left: 0;
    top: -100px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--gray);
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    opacity: 0;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, .25)
}

.header.sticky:before {
    top: 0;
    opacity: 1
}

.header.sticky .header__phone {
    color: #000
}

.header__phone {
    display: block;
    line-height: 1;
    color: #fff;
    position: relative;
    font-weight: 700
}

.header__phone:active,
.header__phone:hover {
    color: #fff;
    text-decoration: none;
    opacity: .8
}

.toggle-menu {
    display: block;
    width: 28px;
    height: 24px;
    margin-left: 15px
}

.toggle-menu span:after,
.toggle-menu span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px
}

.toggle-menu span:after {
    top: 18px
}

.toggle-menu span {
    position: relative;
    display: block
}

.toggle-menu span,
.toggle-menu span:after,
.toggle-menu span:before {
    width: 100%;
    height: 4px;
    background-color: #fff;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 0
}

.toggle-menu span:before {
    width: 70%;
    right: 0;
    left: auto
}

.toggle-menu.on span {
    background-color: transparent
}

.toggle-menu.on span:before {
    -webkit-transform: rotate(45deg) translate(-1px, 0);
    -ms-transform: rotate(45deg) translate(-1px, 0);
    transform: rotate(45deg) translate(-1px, 0);
    width: 100% !important
}

.toggle-menu.on span:after {
    -webkit-transform: rotate(-45deg) translate(6px, -7px);
    -ms-transform: rotate(-45deg) translate(6px, -7px);
    transform: rotate(-45deg) translate(6px, -7px)
}

.toggle-menu--black span,
.toggle-menu--black span:after,
.toggle-menu--black span:before {
    background-color: #000
}

.mob-menu {
    position: fixed;
    right: -100%;
    max-width: 90vw;
    width: 100%;
    background-color: var(--gray);
    z-index: 5;
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    height: 100vh;
    padding: 15px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
    overflow: auto;
}

.mob-menu__close {
    margin-left: auto;
    margin-right: 0;
    cursor: pointer
}

.mob-menu__close span:after,
.mob-menu__close span:before {
    background-color: #000
}

.mob-menu__ul {
    padding: 0;
    margin: 0
}

.mob-menu__li {
    padding: 10px 0;
    list-style-type: none;
    text-align: right
}

.mob-menu__li br {
    display: none
}

.mob-menu__a {
    color: #000;
    font-size: 1.25rem
}

.mob-menu.on {
    right: 0;
    opacity: 1;
    z-index: 129300;
}

.mob-menu__nav {
    margin-top: 60px
}

.soc {
    margin: -10px
}

.soc__item {
    margin: 10px
}

.soc__item:active,
.soc__item:hover {
    opacity: .8
}

.soc__icon {
    max-width: 30px;
    max-height: 30px;
    -o-object-fit: contain;
    object-fit: contain
}

.btn {
    position: relative;
    display: block;
    max-width: 290px;
    width: 100%;
    -webkit-filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .25));
    filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .25));
    color: #fff;
    text-align: center;
    height: 46px;
    font-weight: 700;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer
}

.btn:before {
    content: '';
    bottom: 0;
    right: 0;
    width: 5px;
    height: 5px;
    position: absolute;
    background-color: var(--brown);
    border-radius: 100%
}

.btn--blue:before {
    background-color: var(--blue)
}

.btn--blue .btn__bg {
    background-color: var(--blue)
}

.btn--blue .btn__bg:before {
    background-color: var(--dark-blue)
}

.btn--light-blue:before {
    background-color: var(--light-blue)
}

.btn--light-blue .btn__bg {
    background-color: var(--light-blue)
}

.btn--light-blue .btn__bg:before {
    background-color: var(--dark-blue)
}

.btn--red:before {
    background-color: var(--red)
}

.btn--red .btn__bg {
    background-color: var(--red)
}

.btn--red .btn__bg:before {
    background-color: var(--dark-red)
}

.btn--blue-to-red:before {
    background-color: var(--dark-blue)
}

.btn--blue-to-red .btn__bg {
    background-color: var(--dark-blue)
}

.btn--blue-to-red .btn__bg:before {
    background-color: var(--dark-red)
}

.btn--red-to-blue:before {
    background-color: var(--dark-red)
}

.btn--red-to-blue .btn__bg {
    background-color: var(--dark-red)
}

.btn--red-to-blue .btn__bg:before {
    background-color: var(--dark-blue)
}

.btn__text {
    z-index: 2;
    position: relative;
    margin: auto
}

.btn:hover {
    color: #fff;
    -webkit-filter: none;
    filter: none
}

.btn:hover .btn__bg:before {
    top: -300px;
    left: -300px
}

.btn__bg {
    clip-path: url(../img/btns/btn.svg#standart);
    -webkit-clip-path: url(../img/btns/btn.svg#standart);
    width: 100%;
    height: 100%;
    display: block;
    background-color: var(--brown);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden
}

.btn__bg:before {
    background-color: var(--dark-brown);
    content: '';
    top: 50%;
    left: 50%;
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 100%;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out
}

.btn--long .btn__bg {
    clip-path: url(../img/btns/btn.svg#standart);
    -webkit-clip-path: url(../img/btns/btn.svg#standart)
}

.btn--long .btn__bg:before {
    width: 800px;
    height: 800px
}

.btn--very-long {
    max-width: 100%
}

.btn--very-long .btn__bg {
    clip-path: url(../img/btns/btn.svg#very-long);
    -webkit-clip-path: url(../img/btns/btn.svg#very-long)
}

.btn--very-long .btn__bg:before {
    width: 1200px;
    height: 1200px
}

.blue-line {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative
}

.blue-line:before {
    position: absolute;
    content: '';
    top: 50px;
    left: 15px;
    width: calc(100% - 30px);
    height: 2px;
    background-color: var(--light-blue)
}

.section {
    padding: 35px 0;
    position: relative
}

.section__block {
    padding: 35px 0 35px 15px;
    position: relative
}

.section__block:first-child {
    padding-top: 0
}

.section__block:last-child {
    padding-bottom: 0
}

.section__block:before {
    content: '';
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    background-color: var(--light-blue);
    width: 1px;
    height: 100%;
    position: absolute
}

.section--mb120 {
    margin-bottom: 60px
}

.section--pb120 {
    padding-bottom: 60px
}

.section--gray {
    background-color: #f9f9f9
}

.section--pt0 {
    padding-top: 0
}

.section--cut {
    position: relative
}

.section--cut>* {
    position: relative;
    z-index: 2
}

.section--cut:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%)
}

ul {
    margin: 0;
    padding: 0
}

li {
    list-style-type: none
}

.parking__item:nth-child(2) {
    margin-bottom: 40px
}

.parking__img {
    width: 100vw;
    margin: 0 -15px;
    display: block
}

.parking__list {
    margin: 20px 0;
    padding: 0
}

.parking__li,
.parking__text {
    font-size: 14px
}

.parking__li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0
}

.parking__li,
.parking__plus {
    list-style-type: none
}

.parking__plus {
    font-size: 2.1875rem;
    color: #303030;
    font-weight: 700;
    line-height: 1;
    padding: 0
}

.parking__number {
    font-weight: 900;
    font-size: 2.1875rem;
    color: var(--blue);
    margin-right: 15px;
    line-height: 1
}

.parking__icon {
    width: 30px;
    height: 30px;
    background-size: cover;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px
}

.territory {
    background-color: #f9f9f9
}

.territory__slide {
    position: relative;
    overflow: hidden
}

.territory__slide:before {
    content: "";
    padding-top: 70%;
    float: left
}

.territory__picture {
    max-width: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
}

.territory__picture img,
.territory__picture source {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center
}

.territory__bottom {
    background-color: #afafaf;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 7px;
    min-height: 85px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.territory__texts {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-weight: 700;
    margin-bottom: 15px
}

.territory__text {
    display: none;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    text-align: center
}

.territory__text.active {
    display: block
}

.territory__bottom-item {
    padding: 0 7px
}

.territory__dots {
    display: none;
    margin: -5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
    height: 15px
}

.territory__arrows,
.territory__dots {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.territory .owl-dot {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    margin: 0 5px;
    cursor: pointer;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.territory .owl-dot:hover {
    background-color: var(--blue)
}

.territory .owl-dot:focus {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.territory .owl-dot.active {
    background-color: var(--blue)
}

.territory .owl-dot span {
    display: none
}

.apps .owl-next,
.apps .owl-prev,
.cards .owl-next,
.cards .owl-prev,
.progress .owl-next,
.progress .owl-prev,
.territory .owl-next,
.territory .owl-prev {
    margin: 7px;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    width: 60px;
    cursor: pointer;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.apps .owl-next.disabled,
.apps .owl-next:hover,
.apps .owl-prev.disabled,
.apps .owl-prev:hover,
.cards .owl-next.disabled,
.cards .owl-next:hover,
.cards .owl-prev.disabled,
.cards .owl-prev:hover,
.progress .owl-next.disabled,
.progress .owl-next:hover,
.progress .owl-prev.disabled,
.progress .owl-prev:hover,
.territory .owl-next.disabled,
.territory .owl-next:hover,
.territory .owl-prev.disabled,
.territory .owl-prev:hover {
    opacity: .6
}

.apps .owl-next:focus,
.apps .owl-prev:focus,
.cards .owl-next:focus,
.cards .owl-prev:focus,
.progress .owl-next:focus,
.progress .owl-prev:focus,
.territory .owl-next:focus,
.territory .owl-prev:focus {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.apps .owl-next:before,
.apps .owl-prev:before,
.cards .owl-next:before,
.cards .owl-prev:before,
.progress .owl-next:before,
.progress .owl-prev:before,
.territory .owl-next:before,
.territory .owl-prev:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 16px
}

.apps .owl-next:after,
.apps .owl-prev:after,
.cards .owl-next:after,
.cards .owl-prev:after,
.progress .owl-next:after,
.progress .owl-prev:after,
.territory .owl-next:after,
.territory .owl-prev:after {
    content: "";
    padding-top: 25%;
    float: left
}

.apps .owl-next span,
.apps .owl-prev span,
.cards .owl-next span,
.cards .owl-prev span,
.progress .owl-next span,
.progress .owl-prev span,
.territory .owl-next span,
.territory .owl-prev span {
    display: none
}

.apps .owl-prev:before,
.cards .owl-prev:before,
.progress .owl-prev:before,
.territory .owl-prev:before {
    background-image: url(../img/slider-arrow-left.svg)
}

.apps .owl-next:before,
.cards .owl-next:before,
.progress .owl-next:before,
.territory .owl-next:before {
    background-image: url(../img/slider-arrow-right.svg)
}

.apps .owl-prev:before,
.cards .owl-prev:before,
.progress .owl-prev:before {
    background-image: url(../img/slider-arrow-left-brown.svg)
}

.apps .owl-next:before,
.cards .owl-next:before,
.progress .owl-next:before {
    background-image: url(../img/slider-arrow-right-brown.svg)
}

.apps .owl-nav,
.cards .owl-nav,
.progress .owl-nav {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    margin: 20px -7px 0
}

.cards .owl-nav {
    margin-right: 0
}

.progress__col:nth-child(1) {
    margin-bottom: 40px
}

.progress__slider {
    overflow: hidden;
    position: relative
}

.progress__slider .slick-arrow {
    display: none !important;
}

.progress__slider-item {
    display: block;
    font-size: 1.25rem;
    color: #303030;
    position: relative;
    padding-left: 35px
}

.progress__slider-item:before {
    content: '';
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    border: 5px solid var(--brown);
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 3px
}

.progress__slider-item.active {
    font-weight: 500
}

.progress__slider-item.active:before {
    width: 25px;
    height: 25px;
    left: -2.5px;
    background-color: var(--brown)
}

.progress .owl-nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.progress__slider {
    padding-left: 30px
}

.progress__slider .slick-arrow {
    height: 40px;
    width: auto;
    right: auto;
    left: 0;
    content: ''
}

.progress__slider .slick-arrow img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.progress__slider .slick-arrow:before {
    display: none
}

.progress__slider .slick-prev {
    top: calc(50% - 30px)
}

.progress__slider .slick-next {
    top: calc(50% + 30px)
}

.location {
    position: relative
}

.location__col:nth-child(1) {
    margin-bottom: 40px
}

.location__caption {
    margin-bottom: 20px
}

.location__list-header {
    margin-bottom: 15px
}

.location__list {
    margin: -5px -5px 20px
}

.location__item {
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .875rem
}

.location__icon {
    width: 25px;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin-right: 10px
}

.location__img {
    width: 100vw;
    height: auto;
    margin: 0 -15px;
    display: block
}

.whitebox__img {
    width: 100%
}

.whitebox__img-descr {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    color: #303030;
    margin-top: 15px
}

.whitebox__ul {
    margin: -7px 0 30px;
    padding: 0;
    counter-reset: whitebox
}

.whitebox__li {
    list-style-type: none;
    padding: 5px 0 5px 45px;
    position: relative
}

.whitebox__li:before {
    counter-increment: whitebox;
    content: counter(whitebox);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    background-color: var(--yellow);
    font-size: .875rem;
    font-weight: 900;
    line-height: 1;
    padding: 5px 8px 15px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%)
}

.steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: -15px;
    margin-bottom: 30px
}

.steps__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 15px;
    background-color: #fff
}

.steps__item.done .steps__circle:after {
    content: '';
    top: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-image: url(../img/done.svg);
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 2
}

.steps__icon-wrap {
    position: relative
}

.steps__circle {
    background-color: var(--brown);
    border-radius: 100%;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    opacity: .6;
    width: 50px
}

.steps__circle:before {
    content: "";
    padding-top: 100%;
    float: left
}

.steps__icon {
    margin: auto;
    width: 50%;
    height: 50%;
    -o-object-fit: contain;
    object-fit: contain
}

.steps__text {
    font-size: 1rem;
    line-height: 1.3;
    opacity: .6
}

.steps__item.done .steps__circle,
.steps__item.done .steps__text,
.steps__item.proccess .steps__circle,
.steps__item.proccess .steps__text {
    opacity: 1
}

.steps__item:last-child .steps__icon-wrap:after {
    display: none !important
}

.steps__item .steps__icon-wrap:after {
    content: '';
    top: 75%;
    height: 100%;
    left: 23px;
    width: 2px;
    border: 2px dashed #ccc;
    position: absolute;
    z-index: -2;
    opacity: .6
}

.steps__item.done .steps__icon-wrap:after {
    border: 2px solid var(--yellow);
    opacity: 1
}

.steps__item.proccess .steps__icon-wrap:after {
    border: 2px dashed var(--yellow);
    opacity: 1
}

.steps__item.proccess .steps__icon-wrap:before {
    content: '';
    top: 75%;
    width: 2px;
    height: 100%;
    left: 23px;
    border: 2px solid var(--yellow);
    position: absolute;
    z-index: -1;
    -webkit-animation: proccess 2s infinite ease-in-out;
    animation: proccess 2s infinite ease-in-out
}

@-webkit-keyframes proccess {
    0% {
        top: 0
    }

    100% {
        top: 100%
    }
}

@keyframes proccess {
    0% {
        top: 0
    }

    100% {
        top: 100%
    }
}

@-webkit-keyframes proccessDesc {
    0% {
        right: 0
    }

    100% {
        right: -150%
    }
}

@keyframes proccessDesc {
    0% {
        right: 0
    }

    100% {
        right: -150%
    }
}

.footer {
    padding: 35px 0;
    background-color: #fff
}

.footer--page404 {
    background-color: #ccc
}

.footer-logo {
    text-align: center
}

.footer-logo__img {
    max-width: 270px;
    max-height: 35px;
    -o-object-fit: contain;
    object-fit: contain
}

.develop__description {
    font-size: .875rem;
    padding: 20px;
    border: 1px solid rgba(48, 48, 48, .55)
}

.develop__description p {
    padding: 0;
    margin-bottom: 15px
}

.develop__description p:last-child {
    margin: 0
}

.develop__col--left {
    margin-bottom: 35px
}

.develop__list-caption {
    font-size: 1.25rem;
    margin-bottom: 15px
}

.develop__num {
    font-size: 2.8125rem;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 7px;
    color: var(--red)
}

.develop__num-text {
    font-size: .875rem
}

.develop__ul {
    margin: -7px
}

.develop__li {
    padding: 7px
}

.develop__li .btn {
    margin-top: 15px
}

.labels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px -10px 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.labels__item {
    width: 33%;
    padding: 10px;
    text-align: center
}

.labels__img {
    max-width: 70px;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain
}

.square-gallery__caption {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 25px
}

.square-gallery__content {
    position: relative;
    z-index: 1;
    color: #fff
}

.square-gallery__name {
    font-size: .9375rem;
    font-weight: 700;
    text-transform: uppercase
}

.square-gallery__address {
    font-size: .8125rem
}

.square-gallery__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.square-gallery__picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.square-gallery__item {
    position: relative;
    background-color: #f9f9f9;
    overflow: hidden;
    padding: 20px;
    display: block
}

.square-gallery__item:hover:after {
    opacity: 1
}

.square-gallery__item:before {
    content: "";
    padding-top: 100%;
    float: left
}

.square-gallery__item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    opacity: .5;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.contacts__ul {
    margin: -7px 0
}

.contacts__li {
    font-size: 1.125rem;
    padding: 7px 0
}

.contacts__li span {
    display: block
}

.contacts__li span:nth-child(1) {
    font-weight: 900
}

.contacts__map,
.contacts__map-container {
    width: 100%;
    height: 100%
}

.contacts__map-container {
    position: relative;
    height: 250px;
    margin: 30px -15px 0;
    width: 100vw
}

.contacts__map-container--location {
    height: 450px
}

.contacts__map {
    position: absolute;
    overflow: hidden;
    background-color: var(--gray)
}

.contacts__map:before {
    position: absolute;
    top: -50%;
    left: -50%;
    content: '';
    -webkit-animation: map 2s infinite linear;
    animation: map 2s infinite linear;
    width: 20px;
    height: 300%;
    background-color: #fff;
    -webkit-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
    -webkit-filter: blur(30px);
    filter: blur(30px)
}

@-webkit-keyframes map {
    0% {
        left: -50%
    }

    100% {
        left: 100%
    }
}

@keyframes map {
    0% {
        left: -50%
    }

    100% {
        left: 100%
    }
}

.tabs__header {
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    box-shadow: 0 0 4px rgba(0, 0, 0, .25)
}

.tabs__header--bottom {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 30px
}

.tabs__tab {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    padding: 7px 3px;
    cursor: pointer;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    background-color: #fff;
    color: #303030;
    display: block
}

.tabs__tab:hover {
    background-color: var(--light-brown);
    color: #fff
}

.tabs__tab.active,
.tabs__tab.active:hover {
    background-color: var(--brown);
    color: #fff;
    cursor: default
}

.tabs__tab--blue:hover {
    background-color: var(--blue);
    color: #fff
}

.tabs__tab--blue.active,
.tabs__tab--blue.active:hover {
    background-color: var(--light-blue);
    color: #fff;
    cursor: default
}

.tabs__tab--yellow {
    font-size: 1.125rem;
    -webkit-box-shadow: 0 0 0 1px var(--yellow) inset;
    box-shadow: 0 0 0 1px var(--yellow) inset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    line-height: 1.4
}

.tabs__tab--yellow:hover {
    background-color: var(--light-yellow);
    color: #fff
}

.tabs__tab--yellow span {
    width: 100%;
    text-align: center
}

.tabs__tab--yellow.active,
.tabs__tab--yellow.active:hover {
    background-color: var(--yellow);
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff
}

.tabs__tab--brown {
    font-size: 1.125rem;
    -webkit-box-shadow: 0 0 0 1px var(--brown) inset;
    box-shadow: 0 0 0 1px var(--brown) inset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    line-height: 1.4
}

.tabs__tab--brown:hover {
    background-color: var(--brown);
    color: #fff
}

.tabs__tab--brown span {
    width: 100%;
    text-align: center
}

.tabs__tab--brown.active,
.tabs__tab--brown.active:hover {
    background-color: var(--brown);
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff
}

.tabs__blocks {
    margin: -20px;
    position: relative;
    z-index: 1
}

.tabs__block {
    display: none;
    width: 100%;
    padding: 20px
}

.tabs__block.active {
    display: block
}

.about__bg {
    margin: 0 -15px;
    width: 100vw;
    display: block;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: top center;
    object-position: top center
}

.about__text {
    margin: 0 0 30px
}

.about__text p:last-child {
    margin-bottom: 0
}

.about__btn {
    margin: 30px 0
}

.about__ul {
    counter-reset: about
}

.about__li {
    list-style-type: none;
    padding: 0 0 0 35px;
    position: relative;
    min-height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.about__li:before {
    width: 24px;
    counter-increment: about;
    content: counter(about);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    background-color: var(--yellow);
    font-size: .875rem;
    font-weight: 900;
    line-height: 1;
    padding: 5px 0 15px;
    text-align: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%)
}

.cards__img {
    height: 60px;
    width: auto;
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    margin: 0 auto
}

.cards__item {
    height: 100%
}

.cards__wrap {
    -webkit-box-shadow: 2px 4px 15px rgba(0, 0, 0, .25);
    box-shadow: 2px 4px 15px rgba(0, 0, 0, .25);
    padding: 15px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff
}

.cards__ul {
    margin: 20px -3px;
    text-align: center
}

.cards__li {
    padding: 3px;
    font-size: .875rem
}

.cards__li span:nth-child(2) {
    font-weight: 700;
    margin-left: 5px
}

.cards__btn {
    margin-top: auto
}

.cards__btn .btn {
    margin: auto
}

.cards-slider .owl-stage {
    padding-left: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.cards-slider .owl-item {
    height: auto
}

.cards-slider .owl-stage-outer {
    overflow: visible !important
}

.form--contacts {
    margin-top: 40px;
    max-width: 400px
}

.form__header {
    margin-bottom: 20px
}

.form__caption {
    font-size: 1.375rem;
    font-weight: 700
}

.form__caption--app {
    line-height: 1.2
}

.form__description {
    font-size: .875rem
}

.form__input,
.form__select,
.form__textarea {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border: 0;
    padding: 10px 15px;
    font-size: .875rem
}

.form__input,
.form__select {
    max-width: 100%;
    min-width: 1px;
    width: 100%;
    background-color: #fff
}

.form__label {
    font-size: .875rem;
    margin-bottom: 3px;
    font-weight: 700
}

.form__select {
    height: 43px;
    color: rgba(51, 51, 51, .5)
}

.form__select option {
    color: #333
}

.form__textarea {
    resize: none;
    width: 100%;
    height: 85px
}

.form__row {
    margin: -7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.form__btn-wrap,
.form__input-wrap,
.form__textarea-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    padding: 7px;
    position: relative
}

.form__btn-wrap.required:before,
.form__input-wrap.required:before,
.form__textarea-wrap.required:before {
    position: absolute;
    top: 15px;
    right: 15px;
    content: '*';
    color: #b20000;
    font-size: 1.25rem;
    display: block;
    line-height: 1
}

.form__btn {
    width: 100%;
    max-width: 100%
}

.progress__slider.owl-carousel {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 100%;
    height: 100%
}

.progress__slider.owl-carousel .progress__date {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.hover-magnifier {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .24s ease-in-out;
    -o-transition: all .24s ease-in-out;
    transition: all .24s ease-in-out
}

.hover-magnifier__icon {
    height: 25px;
    width: auto;
    margin: auto;
    opacity: 0;
    position: absolute;
    bottom: 30%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    -webkit-transition: all .25s ease-in-out .25s;
    -o-transition: all .25s ease-in-out .25s;
    transition: all .25s ease-in-out .25s
}

.apps__slider-img-wrap {
    position: relative;
    overflow: hidden;
    background: #f9f9f9
}

.apps__slider-img-wrap:before {
    padding-top: 100%;
    float: left;
    content: ''
}

.apps__slider-img {
    -webkit-transition: all .4s ease-in-out !important;
    -o-transition: all .4s ease-in-out !important;
    transition: all .4s ease-in-out !important
}

.apps__slider-wrap {
    display: block
}

.apps__slider-wrap:hover .apps__slider-img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.apps__slider-wrap:hover .hover-magnifier {
    opacity: 1
}

.apps__slider-wrap:hover .hover-magnifier__icon {
    opacity: 1;
    bottom: 50%
}

.apps__slider-text {
    text-align: center;
    margin: 10px 0 10px;
    color: #303030
}

.apps__slider-name {
    display: inline-block;
    padding: 0 20px;
    font-size: 1.25rem;
    color: #fff;
    background-color: var(--brown);
    margin-bottom: 20px
}

.apps__col {
    overflow: hidden
}

.apps__ul {
    overflow-x: scroll;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: -10px -10px 25px -10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.apps__ul-wrap {
    position: relative
}

.apps__ul-wrap:before {
    z-index: 2;
    position: absolute;
    content: '';
    top: 0;
    right: -10px;
    height: 100%;
    width: 100px;
    pointer-events: none;
    background: -o-linear-gradient(135deg, #f9f9f9 50%, rgba(249, 249, 249, 0) 100%);
    background: linear-gradient(315deg, #f9f9f9 50%, rgba(249, 249, 249, 0) 100%)
}

.apps__li {
    word-wrap: normal;
    cursor: pointer;
    padding: 10px;
    font-size: 1.25rem;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.apps__li:last-child {
    padding-right: 100px
}

.apps__li.active {
    cursor: default;
    font-weight: 700
}

.apps__slider {
    min-height: 300px
}

.mfp-move-from-top .mfp-content {
    opacity: 0;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px)
}

.mfp-move-from-top.mfp-bg {
    opacity: 0;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.mfp-move-from-top.mfp-ready .mfp-content {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.mfp-move-from-top.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-move-from-top.mfp-removing .mfp-content {
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
    opacity: 0
}

.mfp-move-from-top.mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-move-horizontal.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.mfp-move-horizontal.mfp-bg.mfp-ready {
    opacity: .85
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.mfp-move-horizontal .mfp-content {
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0
}

.mfp-move-horizontal.mfp-ready .mfp-content {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
}

.mfp-move-horizontal.mfp-removing .mfp-content {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0
}

.popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 430px;
    margin: 20px auto
}

.popup__header {
    margin-bottom: 25px
}

.popup__caption {
    font-size: 1.5625rem;
    margin-bottom: 5px;
    line-height: 1.4;
    font-weight: 700
}

.mfp-close {
    color: #fff !important;
    opacity: 1;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.mfp-close:hover {
    opacity: .8
}

.mfp-close:before {
    content: '';
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url(../img/mfp-close.svg);
    background-size: cover
}

.checkmark-wrapper {
    width: 100px;
    display: block;
    height: auto;
    margin: 0 auto
}

.checkmark {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
    width: 100px;
    height: 100px
}

.checkmark.active {
    -webkit-animation: dash 2s ease-in forwards 1;
    animation: dash 2s ease-in forwards 1
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 100
    }

    100% {
        stroke-dashoffset: 0
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 100
    }

    100% {
        stroke-dashoffset: 0
    }
}

.success-popup {
    text-align: center;
    max-width: 400px;
    padding: 40px 30px
}

.success-popup__caption {
    font-size: 1.875rem;
    font-weight: 700;
    margin: 15px 0 10px;
    line-height: 1.4
}

.text-block p,
.text-block ul {
    margin-bottom: 15px;
    padding-bottom: 0
}

.text-block:last-child {
    margin-bottom: 0
}

.text-block li {
    padding: 3px 3px 3px 25px;
    position: relative
}

.text-block li:before {
    content: '';
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: var(--blue);
    border-radius: 100%;
    position: absolute;
    left: 0
}

.text-block li:nth-child(2n):before {
    background-color: var(--brown)
}

.text-block li:nth-child(3n):before {
    background-color: var(--light-blue)
}

.text-block ul {
    margin: -3px -3px 15px 0
}

.load {
    position: relative;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out
}

.load:after,
.load:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 3;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out
}

.load:after {
    background-image: url(../img/spinner.svg);
    background-size: cover;
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    -webkit-animation: spinner 2s infinite linear;
    animation: spinner 2s infinite linear;
    display: block
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.quiz {
    margin: 0 auto
}

.quiz__col--btn {
    margin-top: 35px
}

.quiz__block {
    margin-bottom: 20px
}

.quiz__block-caption,
.quiz__field-caption {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 1
}

.quiz__input,
.quiz__select {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border: 0;
    padding: 10px 15px;
    font-size: .875rem;
    max-width: 100%;
    min-width: 1px;
    width: 100%;
    background-color: #fff;
    height: 43px;
    color: rgba(51, 51, 51, .5)
}

.quiz__input option,
.quiz__select option {
    color: #333
}

.quiz__checkboxes,
.quiz__fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -3px
}

.quiz__checkbox-wrap,
.quiz__field-wrap {
    padding: 3px;
    position: relative
}

.quiz__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.quiz__checkbox-label {
    margin-bottom: 0;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    font-size: 1rem;
    padding: 10px 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    width: 100%;
    text-align: center;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.quiz__checkbox-label span {
    display: block;
    width: 100%
}

.quiz__checkbox-2,
.quiz__field-wrap {
    width: 50%
}

.quiz__checkbox-3 {
    width: 33%
}

.quiz__checkbox:checked+label {
    background-color: var(--light-blue);
    color: #fff
}

[data-hide] {
    display: none
}

.quiz .slider-container {
    margin-top: 33px;
    margin-bottom: 33px
}

.quiz .slider-container .back-bar {
    background-color: #b6b6b6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#b6b6b6), to(#b6b6b6));
    background-image: -o-linear-gradient(top, #b6b6b6, #b6b6b6);
    background-image: linear-gradient(to bottom, #b6b6b6, #b6b6b6);
    border-radius: 0;
    height: 5px
}

.quiz .theme-green .back-bar .selected-bar {
    background-color: var(--blue);
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--blue)), to(var(--blue)));
    background-image: -o-linear-gradient(top, var(--blue), var(--blue));
    background-image: linear-gradient(to bottom, var(--blue), var(--blue))
}

.quiz .theme-green .back-bar .pointer {
    border: 0;
    background-color: var(--light-blue);
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--light-blue)), to(var(--light-blue)));
    background-image: -o-linear-gradient(top, var(--light-blue), var(--light-blue));
    background-image: linear-gradient(to bottom, var(--light-blue), var(--light-blue))
}

.quiz .slider-container .back-bar .pointer-label {
    color: #303030;
    font-size: .875rem;
    top: -25px
}

.quiz .theme-green .scale ins {
    color: #303030
}

.quiz .slider-container .scale {
    top: 9px
}

.anim-element {
    display: none !important
}

.progress__doc {
    margin-top: 30px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end
}

.progress__images-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -5px
}

.progress__image-wrap {
    width: calc(50% - 10px);
    margin: 5px;
    position: relative;
    overflow: hidden
}

.progress__image-wrap:before {
    content: "";
    padding-top: 75%;
    float: left
}

.progress__image-wrap:hover .progress__image {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.progress__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-position: cover;
    object-position: cover;
    -webkit-transition: all .25s ease-in-out !important;
    -o-transition: all .25s ease-in-out !important;
    transition: all .25s ease-in-out !important
}

.hover-effect:hover:after {
    opacity: 1
}

.hover-effect:after {
    opacity: 0;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    content: ''
}

.page-404 {
    background-color: #f9f9f9;
    color: #303030
}

.page-404__h1 {
    font-weight: 900;
    font-size: 12.5rem;
    line-height: 1;
    margin-bottom: 20px
}

.page-404__h1 span {
    line-height: 1
}

.page-404__h1 span:nth-child(1) {
    color: var(--blue)
}

.page-404__h1 span:nth-child(2) {
    color: var(--brown)
}

.page-404__h1 span:nth-child(3) {
    color: var(--light-blue)
}

.page-404__description {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 1.25rem
}

.app {
    max-width: 1100px;
    padding: 0 0 30px 0
}

.app__col--sliders {
    margin-bottom: 30px;
    margin-top: 30px
}

.app__top-slider {
    height: auto;
    padding-bottom: 15px
}

.app__bottom-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.app__bottom-slider .slick-list,
.app__bottom-slider .slick-track {
    height: 100%
}

.app__top-img {
    margin: auto;
    max-width: 100%;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
    display: block
}

.app__top-item {
    overflow: hidden
}

.app__top-item:before {
    padding-top: 77.77%;
    float: left;
    content: ''
}

.app__bottom-item {
    margin: 2px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 120px;
    margin: 0 5px
}

.app__bottom-item.slick-current:after {
    opacity: 1;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    content: ''
}

.app__bottom-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.app__bottom-slider {
    padding: 10px;
    height: 100px;
    border: 1px solid var(--brown)
}

.app__caption {
    background-color: var(--light-blue);
    color: #fff;
    font-weight: 900;
    display: inline-block;
    font-size: 1.5625rem;
    padding: 5px 35px
}

.app__row {
    padding: 0 15px
}

.app__ul {
    margin: -3px
}

.app__li {
    padding: 3px;
    font-size: 1.125rem;
    font-weight: 900
}

.app__look-caption {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 3px 0
}

.app__look-list {
    padding: 5px;
    border: 1px solid var(--light-blue)
}

.app__look-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    position: relative;
    min-height: 100px;
    margin: 5px;
    cursor: pointer
}

.app__look-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.app__look {
    margin-bottom: 20px
}

.pos-r {
    position: relative
}

.finger {
    display: block;
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 2;
    height: 30px;
    z-index: 3;
    opacity: 1;
    pointer-events: none;
    -webkit-animation: finger 2s infinite ease-in-out;
    animation: finger 2s infinite ease-in-out;
    width: 20px
}

@-webkit-keyframes finger {
    0 {
        right: 15px
    }

    100% {
        right: 45px;
        opacity: 0
    }
}

@keyframes finger {
    0 {
        right: 15px
    }

    100% {
        right: 45px;
        opacity: 0
    }
}

.develop__btn {
    margin: 30px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

#jGrowl {
    display: none !important
}

input.error {
    -webkit-box-shadow: 0 0 2px red !important;
    box-shadow: 0 0 2px red !important
}

.app-popup {
    min-height: 80vh
}

.apps__slider,
.progress__images {
    height: 100%
}

.head_center {
    display: flex;
    align-items: center;
}

.h_btn + .h_btn {
    margin-top: 0 !important;
    margin-left: 20px;
}

@media (min-width:576px) {
    [data-aos][data-aos][data-aos-duration="400"],
    body[data-aos-duration="400"] [data-aos] {
        transition-duration: .8s !important;
    }
    .caption__h2 .aos-init, .caption__h2.aos-init {
        -webkit-transition: 0.8s !important;
           -moz-transition: 0.8s !important;
            -ms-transition: 0.8s !important;
             -o-transition: 0.8s !important;
                transition: 0.8s !important;
    }
    .labels__item {
        width: 20%
    }

    .about__ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .about__col {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0
    }

    .about__li {
        min-height: 60px
    }
}

@media (min-width:768px) {
    body {
        overflow-x: visible
    }

    .finger {
        display: none
    }

    .steps {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .steps__item {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .steps__circle {
        margin: 0 auto 10px;
        width: 70px
    }

    .steps__text {
        font-size: .75rem;
        text-align: center
    }

    .steps__icon {
        width: 40%;
        height: 40%
    }

    .steps__item .steps__icon-wrap:after {
        top: calc(50% - 5px);
        width: 150%;
        right: -150%;
        left: auto;
        height: 2px
    }

    .steps__item.proccess .steps__icon-wrap:before {
        top: calc(50% - 5px);
        width: 150%;
        right: -150%;
        height: 2px;
        left: auto;
        -webkit-animation: proccessDesc 2s infinite ease-in-out;
        animation: proccessDesc 2s infinite ease-in-out
    }

    .quiz {
        max-width: 1150px
    }

    .quiz__row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: -15px
    }

    .quiz__col {
        width: 50%;
        padding: 15px
    }

    .quiz__block {
        margin-bottom: 30px
    }

    .quiz__block--range {
        padding: 10px 0
    }

    .quiz__block--mb0 {
        margin-bottom: 0
    }

    .quiz__block-caption,
    .quiz__field-caption {
        font-size: 1.5rem;
        margin-bottom: 25px
    }

    .cards {
        margin: -5px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .cards__img {
        margin: 0 auto
    }

    .cards__item {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        padding: 5px;
        height: auto
    }

    .cards__ul {
        text-align: left
    }

    .cards__btn .btn {
        margin: 0
    }

    .cards-slider .owl-stage-outer {
        overflow: hidden !important
    }

    .territory__text {
        padding: 0 10px;
        font-size: .875rem;
        text-align: left
    }

    .territory__texts {
        margin-bottom: 0
    }

    .territory__bottom {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .territory__dots {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        margin-bottom: 0
    }

    .territory__arrows {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 7px -7px;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .blue-caption,
    .caption {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .blue-caption__h2,
    .caption__h2 {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 20px
    }

    .blue-caption__whitebox,
    .caption__whitebox {
        margin-left: 20px
    }

    .about__bg {
        width: auto;
        margin: 0
    }

    .about__btn {
        margin-bottom: 0
    }

    .labels {
        margin-top: 45px
    }

    .h2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .h2__img {
        margin-left: 30px
    }

    .tabs__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .tabs__tab {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        font-size: 1rem
    }

    .location__img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        margin: 0
    }

    .location__col:nth-child(1) {
        margin-bottom: 0
    }

    .contacts__map-container {
        width: 100%;
        margin: 0;
        height: 100%
    }

    .app__col--content,
    .app__col--sliders {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 30px
    }

    .app__col--content .form-wrapper {
        margin-top: auto
    }

    .app__wrapper:nth-child(1) {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media (min-width:992px) {
    .whitebox__img-descr {
        margin-top: 30px
    }

    .whitebox__li:nth-child(2n) {
        margin-left: 65px
    }

    .tabs .tabs__tab--brown,
    .tabs .tabs__tab--yellow {
        padding: 30px 5px
    }

    .apps__col:nth-child(1) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .apps__ul {
        overflow-x: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: -4px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%
    }

    .apps__ul-wrap {
        height: 370px
    }

    .apps__ul-wrap:before {
        display: none
    }

    .apps__li {
        padding: 4px
    }

    .apps__li:last-child {
        padding-right: 0
    }

    .cards {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0
    }

    .cards__li {
        font-size: 1rem
    }

    .cards__img {
        -ms-flex-item-align: start;
        align-self: start
    }

    .cards__item {
        padding: 15px
    }

    .cards__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        padding: 20px
    }

    .cards__btn {
        margin-top: auto
    }

    .blue-caption__h2,
    .caption__h2 {
        margin-right: 50px
    }

    .blue-caption__description,
    .caption__description {
        font-size: 1.125rem
    }

    .about__li {
        font-size: .875rem
    }

    .about__text {
        margin: 50px 0 35px
    }

    .about__btn {
        margin-top: 50px
    }

    .square-gallery__caption {
        text-align: center;
        font-size: 1.625rem;
        margin-bottom: 35px
    }

    .develop__ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .develop__li {
        width: 50%
    }

    .labels__item {
        width: 10%
    }

    .blue-caption,
    .caption,
    .h2 {
        margin-bottom: 40px
    }

    .blue-caption__caption,
    .blue-caption__h2,
    .caption__caption,
    .caption__h2,
    .h2__caption,
    .h2__h2 {
        line-height: 1.4;
        font-size: 2rem
    }

    .section {
        padding: 70px 0
    }

    .section__block {
        padding: 45px 0 45px 15px
    }

    .section__block:first-child {
        padding-top: 0
    }

    .section__block:last-child {
        padding-bottom: 0
    }

    .section--pt0 {
        padding-top: 0
    }

    .section--mb120 {
        margin-bottom: 120px
    }

    .section--pb120 {
        padding-bottom: 120px
    }

    .mob-menu {
        width: 40vw
    }

    .parking__item:nth-child(2) {
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .parking__img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        -o-object-fit: cover;
        object-fit: cover
    }

    .parking__list {
        margin-bottom: 40px
    }

    .parking__content {
        margin-bottom: 0
    }

    .territory__slide:before {
        padding-top: 40%
    }
}

@media (min-width:1200px) {
    .steps__item {
        max-width: 110px;
        background-color: #fff
    }

    @-webkit-keyframes proccessDesc {
        0% {
            right: -30%
        }

        100% {
            right: -150%
        }
    }

    @keyframes proccessDesc {
        0% {
            right: -30%
        }

        100% {
            right: -150%
        }
    }

    .progress__images-flex {
        margin: -15px
    }

    .progress__image-wrap {
        width: calc(50% - 30px);
        margin: 15px;
        position: relative;
        overflow: hidden
    }

    .pos-r-anim {
        position: relative
    }

    .pos-r-anim>* {
        position: relative;
        z-index: 2
    }

    .section--anim {
        position: relative
    }

    .section--anim>* {
        position: relative;
        z-index: 2
    }

    .anim-element {
        position: absolute;
        z-index: 1;
        display: block !important
    }

    .anim-element--figure {
        background-size: contain
    }

    .anim-element--letter {
        font-weight: 900;
        color: var(--brown);
        font-size: 25rem;
        line-height: 400px;
        opacity: .1;
        width: 400px;
        height: 400px
    }

    .anim-element-1 {
        left: 15%;
        top: 20%
    }

    .anim-element-2 {
        right: 15%;
        top: -100px;
        width: 150px;
        height: 150px
    }

    .anim-element-3 {
        left: 20%;
        top: 50%
    }

    .anim-element-4 {
        right: 0;
        width: 90px;
        height: 90px;
        top: -88px
    }

    .anim-element-5 {
        right: 0;
        width: 75px;
        height: 150px;
        top: 50%
    }

    .anim-element-6 {
        left: 40%;
        top: 20%
    }

    .anim-element-7 {
        right: 0;
        width: 600px;
        height: 700px;
        top: 35%
    }

    .anim-element-8 {
        left: calc(50% - 400px);
        width: 800px;
        height: 400px;
        bottom: 0
    }

    .anim-element-9 {
        left: calc(50% - 400px);
        width: 800px;
        height: 400px;
        top: 0
    }

    .anim-element-10 {
        left: 0;
        width: 150px;
        height: 150px;
        bottom: 0
    }

    .anim-element-11 {
        left: 0;
        width: 90px;
        height: 180px;
        top: 60%
    }

    .anim-element-12 {
        left: 50%;
        top: 50%
    }

    .quiz__checkboxes,
    .quiz__fields {
        margin: -10px
    }

    .quiz__checkbox-wrap,
    .quiz__field-wrap {
        padding: 10px
    }

    .quiz__checkbox-label {
        font-size: 1.1875rem
    }

    .whitebox__ul {
        margin-top: -7px;
        margin-bottom: -7px;
        padding: 0 10px 0 30px
    }

    .text-block {
        max-width: 900px;
        margin: auto
    }

    .territory__text {
        padding: 0 50px;
        font-size: 1rem;
        text-align: left
    }

    .location__caption {
        margin-bottom: 30px
    }

    .location__list-header {
        margin-bottom: 25px
    }

    .location__list,
    .location__list-header {
        padding-left: 20px
    }

    .location__icon {
        width: 30px;
        margin-right: 20px
    }

    .location__item {
        font-size: 1rem;
        padding: 7px
    }

    .location__list {
        margin: -7px -7px 50px
    }

    .tabs__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 40px
    }

    .tabs__tab {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        font-size: 1.375rem
    }

    .tabs__header--bottom {
        margin-bottom: 40px
    }

    .tabs .tabs__tab--yellow {
        padding: 40px 5px;
        font-size: 1.375rem
    }

    .develop__list-caption {
        font-size: 1.625rem;
        margin-bottom: 25px
    }

    .develop__numbers {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .develop__num {
        font-size: 2.0625rem;
        margin-right: 10px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-bottom: 0
    }

    .develop__num-text {
        line-height: 1.4
    }

    .develop__li {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 15px
    }

    .develop__li--btn {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .develop__ul {
        margin: -15px
    }

    .labels {
        margin-top: 0
    }

    .labels__item {
        width: auto;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0
    }

    .dev-first__caption {
        font-size: .875rem;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 1.2
    }

    .dev-first__logo {
        height: 40px;
        width: auto;
        -o-object-fit: contain;
        object-fit: contain
    }

    .logo__img,
    .right-logo__img {
        max-height: 35px;
        max-width: 200px;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: right;
        object-position: right
    }

    .right-logo {
        margin-bottom: 20px
    }

    .header__btn {
        min-width: 200px
    }

    .btn--mini {
        max-width: 200px;
        height: 40px;
        font-size: .875rem
    }

    .header {
        padding: 20px 0
    }

    .header__btn {
        min-width: 200px
    }

    .header__phone {
        padding-left: 25px;
        margin-bottom: 20px;
        font-size: 1.25rem
    }

    .header__phone:before {
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        content: '';
        width: 18px;
        height: 18px;
        background-size: cover;
        background-image: url(../img/phone-icon.svg)
    }

    .main__description {
        margin-bottom: 10px
    }

    .main__h1 {
        font-size: 3.4375rem
    }

    .main__h2 {
        font-size: 2rem;
        margin-bottom: 25px
    }

    .main__content {
        margin: auto 0
    }

    .financing {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;
        height: 100%
    }

    .financing__caption {
        font-size: 1.125rem;
        position: relative;
        display: inline-block;
        padding-right: 10px;
        line-height: 1.3;
        margin-bottom: 60px
    }

    .financing__caption:after,
    .financing__caption:before {
        position: absolute;
        top: 50%;
        right: 0;
        content: '';
        height: 100%;
        background-color: var(--yellow);
        width: 2px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .financing__caption:before {
        right: 3px;
        width: 1px
    }

    .financing__logo {
        max-width: 100%
    }

    .soc--desc {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        height: 100vh;
        z-index: 3;
        padding: 12px;
        margin: 0
    }

    .soc--desc .soc__item {
        margin: 15px 0
    }

    .soc--desc .soc__icon {
        max-width: 25px;
        max-height: 25px
    }

    .left-menu {
        position: fixed;
        background-color: #fff;
        -webkit-box-shadow: 2px 3px 4px rgba(0, 0, 0, .25);
        box-shadow: 2px 3px 4px rgba(0, 0, 0, .25);
        width: 40px;
        z-index: 50;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 10px;
        -webkit-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        overflow: hidden;
        line-height: 1.4
    }

    .left-menu:hover {
        width: 220px
    }

    .left-menu:hover .left-menu__span {
        opacity: 1;
        right: auto;
        left: 40px
    }

    .left-menu__ul {
        padding: 0;
        margin: -10px 0
    }

    .left-menu__li {
        padding: 10px 0;
        list-style-type: none
    }

    .left-menu__a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #000;
        position: relative;
        font-weight: 900
    }

    .left-menu__a.active,
    .left-menu__a:hover {
        color: #000
    }

    .left-menu__a.active path,
    .left-menu__a:hover path {
        fill: var(--red) !important
    }

    .left-menu__icon {
        width: 35px;
        height: 35px;
        -o-object-fit: contain;
        object-fit: contain
    }

    .left-menu__icon path {
        -webkit-transition: all .15s ease-in-out;
        -o-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out
    }

    .left-menu__span {
        position: absolute;
        right: -300px;
        -webkit-transition: all .5s ease-in-out .25s;
        -o-transition: all .5s ease-in-out .25s;
        transition: all .5s ease-in-out .25s;
        margin-left: 15px;
        opacity: 0;
        width: 250px;
        font-size: .875rem
    }

    .progress__col:nth-child(1) {
        margin-bottom: 0
    }

    .progress__slider {
        padding: 50px 0
    }

    .progress__slider-item {
        font-size: 1.875rem;
        padding-left: 45px
    }

    .progress__slider {
        padding-left: 60px
    }

    .progress__slider .slick-arrow {
        width: 16px;
        height: 60px
    }

    .progress__slider .slick-prev {
        top: calc(50% - 40px)
    }

    .progress__slider .slick-next {
        top: calc(50% + 40px)
    }
}

@media (min-width:1400px) {
    .steps__item {
        max-width: 120px
    }

    .steps__text {
        font-size: .9375rem;
        margin-left: -10px;
        margin-right: -10px
    }

    .left-menu {
        width: 60px
    }

    .soc--desc {
        padding: 20px
    }

    .soc--desc .soc__icon {
        max-width: 40px;
        max-height: 40px
    }
}

@media (min-width:1920px) {
    .steps__item .steps__icon-wrap:after {
        width: 300%;
        right: -300%
    }

    .steps__item.proccess .steps__icon-wrap:before {
        top: calc(50% - 5px);
        width: 150%;
        right: -150%;
        height: 2px;
        left: auto;
        -webkit-animation: proccessDesc 2s infinite ease-in-out;
        animation: proccessDesc 2s infinite ease-in-out
    }
}


@media (max-width:1140px) {
    .head_bankdom {
        right: 20px;
    }
    .main {
        min-height: 520px;
    }
    .main__col-center:before {
        width: 110px;
        height: 130px;
    }
}

@media (max-width:480px) {
    .pu_inner {
        max-width: 300px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 53px;
        padding-bottom: 40px;
    }
    .pu_inner .align_center {
        margin-top: 20px;
    }
    .pu_title {
        font-size: 20px;
        line-height: 120%;
    }
    .pu_form {
        margin-top: 35px;
    }
    .pu_btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width:840px) {
    .progress {
        height: 520px;
    }
}

@media (max-width:768px) {
    .head_bankdom img {
        max-width: 210px;
    }
    .pu_action {
        top: 20%;
    }
    .pu_action__inner {
        width: 640px;
        min-width: 640px;
    }
    .pu_al__name {
        font-size: 15px;
        line-height: 18px;
    }
    .pu_al__info {
        font-size: 12px;
        padding-left: 10px;
        padding-top: 10px;
        padding-right: 5px;
    }
    .pu_action__list {
        margin-left: -15px;
    }
    .pu_action__list > li {
        padding-left: 15px;
    }
}

@media (max-width:767px) {
    .progress {
        height: 760px;
    }
}

@media (max-width:680px) {
    .pu_action__inner {
        padding: 17px 20px 31px 19px;
        width: 530px;
        min-width: 530px;
    }
}

@media (max-width:570px) {
    .pu_action__title {
        width: 36px;
    }
    .pu_action__title span {
        line-height: 36px;
        margin-top: 0;
    }
    .pu_action__inner_title {
        font-size: 20px;
        line-height: 120%;
    }
    .pu_action {
        -webkit-transform: translateX(calc(100% - 36px));
        -moz-transform: translateX(calc(100% - 36px));
        -ms-transform: translateX(calc(100% - 36px));
        -o-transform: translateX(calc(100% - 36px));
        transform: translateX(calc(100% - 36px));
    }

    .pu_action__open {
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .pu_action__inner_title span {
        font-size: 22px;
        margin-top: 2px;
        line-height: 120%;
    }
    .pu_action__list {
        margin-top: 14px;
        display: block;
        margin-left: 0;
    }

    .pu_action__list > li {
        display: block;
        width: auto;
        padding-left: 0;
    }

    .pu_action__list > li + li {
        margin-top: 10px;
    }

    .pu_al__inner {
        height: 140px;
        overflow: hidden;
    }

    .pu_action__inner {
        width: 240px;
        min-width: 20px;
        max-height: 90vh;
        overflow: auto;
    }
    .pu_al__podrobno {
        left: 10px;
        bottom: 10px;
        font-size: 12px;
    }

    .pu_al__name {
        padding-left: 15px;
        padding-right: 15px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
}

@media (max-width:540px) {
    .h_btn {
        font-size: 12px;
    }
    .header__right {
        flex-direction: column;
        justify-content: flex-end;
    }
    body .toggle-menu {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width:520px) {
    .progress {
        height: auto;
        padding-bottom: 30px;
    }
}

@media (max-width:480px) {
    .head_center {
        width: 90px;
    }
    .h_btn {
        line-height: 100%;
    }
}

@media (max-width:380px) {
    .header__phone {
        font-size: 12px;
    }
    .logo__img {
        max-width: 90px;
    }
    .header__phone {
        font-size: 10px !important;
    }
}

/*action banner*/
.banner_action {
    display: flex;
    padding-bottom: 100px;
    -webkit-align-items: center;
            align-items: center;
}

.banner_action_img {
    width: 100%;
}
.banner_action_img img {
    display: block;
    width: 100%;
}

.banner_action_img img + img {
    display: none;
}
.banner_action_content {
    padding-left: 20px;
    padding-right: 5vw;
}
.bcc__title {
    font-size: 80px;
    color: #343434;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    white-space: nowrap;
}
.bcc__form {
    display: block;
    margin-top: 134px;
}
.bcc__form_title {
    white-space: nowrap;
    color: #184D96;
    font-size: 48px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding-bottom: 97px;
}
.bcc__form input {
    display: block;
    width: 440px;
    background: #FFF;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    border: none;
    height: 70px;
    color: #A9A8A8;
    font-weight: 500;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.bcc__form input:focus {
    color: #343434;
}
.bcc__form input + input {
    margin-top: 29px;
}
.bcc__btn {
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 60px;
    background: #C4122F;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    width: 440px;
    height: 70px;
}
    .bcc__btn:hover {
        opacity: 0.7;
    }
@media (max-width:1600px) {
    .banner_action_img {
        width: 100%;
        min-width: 20px;
    }
    .banner_action_content {
        padding-left: 30px;
        padding-right: 60px;
    }
    .bcc__title {
        font-size: 70px;
    }
    .bcc__form_title {
        font-size: 45px;
    }
    .bcc__form_title {
        padding-bottom: 22px;
    }
    .bcc__form {
        margin-top: 54px;
    }
}
@media (max-width:1500px) {
    .bcc__title {
        font-size: 66px;
    }
    .bcc__form_title {
        font-size: 42px;
    }
}
@media (max-width:1260px) {
    .bcc__title {
        font-size: 50px;
    }
    .bcc__form_title {
        font-size: 33px;
    }
    .bcc__form input {
        width: 100%;
    }
    .bcc__form input + input {
        margin-top: 20px;
    }
    .bcc__btn {
        width: 100%;
    }
    .bcc__btn {
        margin-top: 20px;
    }
}
@media (max-width:1024px) {
    .bcc__title {
        font-size: 32px;
    }
    .bcc__form_title {
        font-size: 22px;
        padding-bottom: 16px;
    }
    .bcc__form input {
        height: 55px;
        font-size: 18px;
    }
    .bcc__btn {
        height: 55px;
        font-size: 18px;
    }
    .bcc__form {
        margin-top: 20px;
    }
}
@media (max-width:720px) {
    .banner_action_content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .banner_action {
        display: block;
    }
    .banner_action_img img {
        display: none;
    }
    .banner_action_img img + img {
        display: block;
    }
    .banner_action {
        padding-bottom: 20px;
    }
    .banner_action_content {
        display: flex;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
        -webkit-justify-content: center;
                justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}
@media (max-width:380px) {
    .bcc__title {
        font-size: 22px;
    }
    .bcc__form_title {
        font-size: 20px;
    }
    .banner_action_content {
        padding-left: 20px;
        padding-right: 20px;
    }
}
/*action banner end*/

.main__col-center {
    position: relative;
}

    .main__col-center:before {
        content: "";
        display: block;
        position: absolute;
        width: 140px;
        height: 165px;
        left: 10px;
        bottom: 20px;
        z-index: 50;
        background: url(../img/urban.png) no-repeat center top;
        -webkit-background-size: 100% auto;
             -o-background-size: 100% auto;
                background-size: 100% auto;
    }

@media (max-width:360px) {
    .main__col-center:before {
        left: 5px;
        bottom: 5px;
        width: 75px;
        height: 89px;
    }
    .header__phone {
        font-size: 13px;
    }
    .main__h2 {
        font-size: 20px;
    }
    .main__h1 {
        font-size: 29px;
    }
    .main__col-center {
        min-height: 20px;
    }
    .main {
        min-height: 400px;
    }
}


@media (max-width:1160px) {
    .popup_action__title {
        font-size: 40px;
    }
    .pu_al__info2 {
        font-size: 18px;
    }
    .pu_al__name2 {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .pu_action__inner {
        width: 850px;
        min-width: 850px;
    }
    .pu_action__list2 {
        margin-top: 22px;
    }
}

@media (max-width:920px) {
    .pu_action__inner {
        min-width: 20px;
        width: 100%;
    }
}

@media (max-width:860px) {
    .pu_al__name2 {
        font-size: 16px;
    }
    .pu_al__info2 {
        font-size: 16px;
    }
    .popup_action__title {
        font-size: 32px;
    }
    .pu_al__info2 {
        padding-left: 10px;
        padding-right: 10px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .pu_al__name2 {
        height: 60px;
    }
}

@media (max-width:768px) {
    .pu_action__inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
}

@media (max-width:680px) {
    .popup_action__title {
        font-size: 26px;
    }
    .pu_action__list2 {
        display: block;
        margin-left: 0;
        padding-bottom: 40px;
    }
    .pu_action__list2 > li {
        padding-left: 0;
        width: 100%;
    }
    .pu_action__list2 > li  + li {
        margin-top: 25px;
    }

    .pu_action {
        overflow: auto;
        max-height: calc(100vh - 12%);
    }

    .pu_action__inner {
        background: none;
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    .pu_action__open {
        background: #FFF;
    }
}

@media (max-width:460px) {
    .pu_action__inner {
        width: 260px;
    }
    .popup_action__title {
        font-size: 22px;
    }
    .pu_action__list2 {
        padding-bottom: 0;
    }
    .pu_action {
        margin-top: 0;
        max-height: calc(100vh - 20%);
    }
    .dev_about {
        font-size: 16px;
        padding-bottom: 20px;
    }
}

.f_politic {
    font-size: 16px;
    line-height: 120%;
    padding-bottom: 30px;
}

.dev_about {
    font-size: 20px;
    line-height: 130%;
    margin-top: 20px;
}

@media (max-width:360px) {
    .dev_about {
        font-size: 14px;
    }
}

/*pu_ipoteka*/
.pu_ipoteka {
    position: fixed;
    z-index: 300;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.3);   
}

.pu_ipoteka_inner {
    position: absolute;
    width: 1200px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
       -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
         -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.closeform {
    position: absolute;
    z-index: 50;
    cursor: pointer;
    right: 20px;
    top: 20px;
    width: 30px;
}

    .closeform img {
        display: block;
        width: 100%;
    }

.pu_ipoteka__img {
    display: block;
    width: 100%;
}

.pu_ipoteka__img + .pu_ipoteka__img {
    display: none;
}

.pu_action_form {
    position: absolute;
    left: 100px;
    top: 370px;
    z-index: 50;
    display: block;
}


.pu_action_form input {
    display: block;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    border: none;
    height: 70px;
    color: #A9A8A8;
    font-weight: 500;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.pu_action_form input + input {
    margin-top: 20px;
}

.pu_action_form button {
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 60px;
    background: #C4122F;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    width: 100%;
    height: 70px;
    margin-top: 20px;
}

.pu_action_form button:hover {
    opacity: 0.7;
}

@media (max-height:720px) {
    .pu_ipoteka_inner {
        width: 840px;
    }
    .pu_action_form {
        left: 30px;
        top: 190px;
    }
}


@media (max-width:1200px) {
    .pu_ipoteka_inner {
        width: 740px;
    }

    .pu_action_form {
        left: 40px;
        top: 209px;
        width: 260px;
    }

    .pu_action_form input {
        height: 50px;
        font-size: 18px;
    }

    .pu_action_form button {
        height: 50px;
        font-size: 18px;
    }

    .pu_action_form input + input, .pu_action_form button {
        margin-top: 15px;
    }

    .toggle-menu {
        margin-left: 0;
        margin-right: 20px;
    }
}

@media (max-width:680px) {
    .h_btn + .h_btn {
        margin-left: 10px !important;
    }
    .h_btn {
        font-size: 12px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .caption__description {
        font-size: 30px;
    }
    .pu_ipoteka_inner {
        width: 480px;
    }

    .pu_action_form {
        left: 20px;
        top: 119px;
        width: 180px;
    }

    .pu_action_form input {
        font-size: 16px;
    }
    .pu_action_form button {
        font-size: 16px;
    }
    .pu_action_form input + input, .pu_action_form button {
        margin-top: 10px;
        height: 40px;
    }
}

@media (max-width:480px) {
    .closeform {
        right: 10px;
        top: 10px;
    }

    .pu_ipoteka__img {
        display: none;
    }

    .pu_ipoteka__img + .pu_ipoteka__img {
        display: block;
    }

    .pu_action_form {
        top: auto;
        bottom: 70px;
    }

    .pu_ipoteka {
        overflow: auto;
        padding-top: 15px;
        padding-bottom: 60px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .pu_ipoteka_inner {
        position: relative;
        width: calc(100% - 30px);
        left: 15px;
        top: 0;
        -webkit-transform: translate(0);
           -moz-transform: translate(0);
            -ms-transform: translate(0);
             -o-transform: translate(0);
                transform: translate(0);
    }

    .pu_action_form {
        width: calc(100% - 40px);
        left: 20px;
    }

    .pu_action_form input + input, .pu_action_form button {
        height: 50px;
    }
}

@media (max-width:420px) {
    .caption__description {
        font-size: 7.5vw;
    }
}

/*white box*/
.wb_main {
    position: relative;
    overflow: hidden;
    padding-left: 105px;
    padding-right: 105px;
}

.wb_inner {
    position: relative;
    max-width: 1625px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 70px;
}

.wb_title {
    position: relative;
    z-index: 10;
    color: #FFF;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px;
    text-transform: uppercase;
    display: inline-block;
}

.wb_title span {
    position: relative;
    z-index: 20;
    display: inline-block;
    padding-right: 73px;
}

.wb_title:before {
    content: "";
    display: block;
    position: absolute;
    width: 30000px;
    height: 100%;
    top: 0;
    z-index: 5;
    right: 0;
    background: #184D96;
}

.wb_info {
    margin-top: 45px;
    color: #1F2225;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.4px;
    position: relative;
    z-index: 10;
    padding-right: 320px;
}

.wb_content {
    display: flex;
    margin-top: 28px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.wbc_title {
    color: #C4122F;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    line-height: 34px; /* 125.926% */
    text-transform: uppercase;
}

.wbc_list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 45px;
    max-width: 575px;
    counter-reset: ol_num;
}

.wbc_list > li {
    color: #303030;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    padding-left: 41px;
}

.wbc_list > li:before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    text-align: center;
    width: 17px;
    counter-increment: ol_num;
    content: counter(ol_num);
    color: #303030;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
}

.wbc_list > li:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 5;
    width: 17px;
    height: 25px;
    left: 0;
    top: 2px;
    background: url("../img/wb_list_ico.svg") no-repeat left top;
}

.wbc_list > li + li {
    margin-top: 30px;
}

.wb_l {
    padding-right: 40px;
    padding-top: 43px;
}

.wb_btn {
    display: inline-block;
    cursor: pointer;
    margin-top: 79px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding-left: 99px;
    padding-right: 99px;
    height: 102px;
    line-height: 102px;
    cursor: pointer;
    background: #C4122F;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .wb_btn:hover {
        background: #F6CC39;
        color: #303030;
    }

.wb_r_title {
    color: #184D96;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 125% */
    text-transform: uppercase;
}

.wb_r {
    max-width: 788px;
    position: relative;
}

.wb_r:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 5;
    width: 3000px;
    height: 3000px;
    left: 100%;
    margin-left: -291px;
    background: #05CBE6;
    top: -500px;
}

.wb_r_inner {
    position: relative;
    z-index: 20;
}

.wb_img {
    margin-top: 59px;
    cursor: pointer;
}

.wb_img img {
    display: block;
    width: 100%;
}

.wb_str {
    display: flex;
    margin-top: 27px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

    .wb_str img {
        display: block;
        cursor: pointer;
        max-width: 100%;
        left: 0;
        position: relative;
        -webkit-transition: 0.3s;
           -moz-transition: 0.3s;
            -ms-transition: 0.3s;
             -o-transition: 0.3s;
                transition: 0.3s;
    }

    .wb_str img:hover {
        left: 10px;
    }

.wb_main2 {}

.wb_main2 .wb_l {
    -webkit-order: 2;
        -ms-order: 2;
            order: 2;
}

.wb_main2 .wb_r {
    -webkit-order: 1;
        -ms-order: 1;
            order: 1;
}

.wb_main2 .wb_l {
    padding-left: 30px;
}

.wb_main2 .wb_r:before {
    margin: 0;
    left: auto;
    right: 100%;
    margin-right: -138px;
}

.wb_main2 .wb_str img {
    -webkit-transform: scale(-1,1);
       -moz-transform: scale(-1,1);
        -ms-transform: scale(-1,1);
         -o-transform: scale(-1,1);
            transform: scale(-1,1);
}

.wb_main2 .wb_str img:hover {
    left: -10px;
}

.wb_title_mobile {
    display: none;
}

.wb_main2 {
    display: none;
}



@media screen and (max-width: 1440px) {
    .wb_r_title {
        font-size: 36px;
    }
    .wb_img {
        margin-top: 19px;
    }
    .wbc_list > li {
        font-size: 16px;
        line-height: 20px;
    }
    .wbc_list > li:before {
        line-height: 20px;
    }
    .wbc_list > li:after {
        top: -1px;
    }
    .wbc_list > li + li {
        margin-top: 18px;
    }
    .wb_btn {
        margin-top: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .wb_title {
        font-size: 38px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .wb_title span {
        padding-right: 40px;
    }
    .wb_info {
        margin-top: 30px;
    }
    .wb_main {
        padding-left: 50px;
        padding-right: 30px;
    }

    .wb_inner {
        padding-top: 20px;
    }

    .wb_info {
        line-height: 24px;
    }

    .wb_title {
        font-size: 34px;
        line-height: 120%;
    }

    .wb_l {
        padding-top: 23px;
    }

    .wbc_list {
        margin-top: 25px;
    }

    .wbc_list > li + li {
        margin-top: 10px;
    }

    .wb_content {
        margin-top: 16px;
    }

    .wb_r {
        max-width: 600px;
    }

    .wb_btn {
        margin-top: 23px;
    }
}

@media screen and (max-width: 1024px) {
    .wb_r_title {
        font-size: 28px;
        white-space: nowrap;
    }
    .wbc_title {
        font-size: 22px;
    }

    .wb_str img {
        max-width: 100px;
    }
    .wb_r:before {
        margin-left: -130px;
    }
    .wb_main2 .wb_r:before {
        margin-right: -74px;
    }
    .wb_inner {
        padding-top: 40px;
        padding-bottom: 40px;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .wb_l {
        padding-right: 0;
    }

    .wb_main1 .wb_l {
        padding-right: 20px;
    }
    .wb_info {
        padding-right: 150px;
    }
} 

@media screen and (max-width: 740px) {
    .wb_main {
        display: block !important;
    }
    .wb_title  {
        font-size: 5.5vw;
        margin-right: -30px;
    }

    .wb_title:before {
        padding-right: 1.4vw;
    }
    .wb_content {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .wb_l {
        padding: 0 !important;
    }

    .wb_r:before {
        display: none !important;
    }

    .wb_r_title {
        display: none;
    }

    .wb_title {
        display: none;
    }

    .wb_title_mobile {
        display: block;
        color: #184D96;
        font-family: Montserrat;
        font-size: 21px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        text-transform: uppercase;
        font-size: 5.7vw;
    }

    .wb_title_mobile span {
        display: block;
        padding-left: 3vw;
    }

    .wb_main {
        padding-left: 20px;
        padding-right: 20px;
    }

    .wb_img {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .wb_main2 .wb_l {
        margin-top: 41px;
    }

    .wb_main2 .wbc_title {
        color: #E0833C;
    }

    .wbc_list {
        margin-top: 19px;
        max-width: 100%;
    }

    .wb_main2 .wb_btn {
        background: #E0833C;
    }

    .wb_btn {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
           -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
             -o-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .wb_l {
        width: 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .wb_main1 .wb_l {
        margin-top: 41px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }

    .wb_info {
        padding-right: 0;
    }

    .wb_btn {
        font-size: 12px;
        height: 50px;
        line-height: 50px;
        padding-left: 49px;
        padding-right: 49px;
    }

    .wb_main1 .wb_r {
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
}

@media screen and (max-width: 420px) {
    .wb_info {
        font-size: 15px;
        line-height: 20px;
    }

    .wbc_title {
        font-size: 20px;
        line-height: 120%;
    }

    .wbc_list {
        margin-top: 24px;
    }

    .wb_main2 .wb_content {
        margin-top: 0;
    }

    .wb_main2 .wb_inner {
        padding-top: 0;
    }
}
/*white box end*/

/*plan flat*/
.flat {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 59px;
    padding-bottom: 168px;
    position: relative;
}
    .flat:before {
        content: "";
        display: block;
        position: absolute;
        width: 26vw;
        height: 100%;
        right: 0;
        top: 0;
        background: #FFDA5A;
    }
.flat h2 span {
    position: relative;
    z-index: 10;
}
.flat_inner {
    position: relative;
    z-index: 50;
    max-width: 1400px;
    margin: 0 auto;
}
.flat .swiper-button-prev, .flat .swiper-button-next {
    position: absolute;
    cursor: pointer;
    z-index: 50;
    top: 50%;
    width: auto;
    height: auto;
    -webkit-transition: 0.1s;
       -moz-transition: 0.1s;
        -ms-transition: 0.1s;
         -o-transition: 0.1s;
            transition: 0.1s;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
.flat .swiper-button-prev:hover, .flat .swiper-button-next:hover {
    -webkit-transform: translateY(-50%) scale(1.02);
       -moz-transform: translateY(-50%) scale(1.02);
        -ms-transform: translateY(-50%) scale(1.02);
         -o-transform: translateY(-50%) scale(1.02);
            transform: translateY(-50%) scale(1.02);
}
.flat .swiper-button-prev svg, .flat .swiper-button-next svg {
    display: block;
}
.flat .swiper-button-prev {
    left: 10px;
}
.flat .swiper-button-next {
    right: 10px;
}
.flat_nav {
    display: flex;
    margin-top: 56px;
    -webkit-align-items: center;
            align-items: center;
}
.flat_nav div {
    margin-right: 55px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    line-height: 120%;
    border-bottom: 1px solid #000;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}
.flat_nav div + div {
    margin-right: 27px;
}
.flat_nav div:hover, .flat_nav div.act {
    color: #E36A0D;
    border-color: transparent;
}
.flat_slider {
    margin-top: 80px;
    padding: 20px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: -32px;
    margin-right: -32px;
}
.flat_slider .swiper-button-disabled {
    display: none;
}
.flat_slide {
    cursor: pointer;
    box-shadow: 0px 1px 16px 2px rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 43px 20px 43px 20px;
}
.fs_img {
    display: flex;
    height: 214px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.fs_img img {
    display: block;
    max-width: 100%;
    max-height: 214px;
}
.fs_name {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
    margin-top: 64px;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDD;
}
.fs_area {
    margin-top: 16px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.fs_area span {
    font-size: 10px;
    color: #D3D3D3;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 120%;
    text-transform: uppercase;
}
.fs_area b {
    margin-left: 16px;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.fs_area b sup {
    display: inline-block;
    margin-left: 3px;
}
.fs_btn {
    display: flex;
    text-align: center;
    width: 100%;
    height: 36px;
    background: #C4122F;
    font-size: 16px;
    color: #FFF;
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 1px;
    margin-top: 22px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}
.fs_btn:hover {
    background: #F6CC39;
}
.pu_flat {
    max-width: 923px;
    overflow: hidden;
    padding: 57px 54px 100px 64px;
}
.pu_inner h2 span {
    position: relative;
    z-index: 10;
}
.pu_flat_inner {
    display: flex;
    margin-top: 36px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.pfi__right {
    width: 407px;
    min-width: 407px;
}
.pfi__right img {
    display: block;
    width: 100%;
}
.pfi__left {
    padding-right: 20px;
    width: 326px;
}
.pfi__info {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}
.pfi__info > li {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.pfi__info > li + li {
    margin-top: 8px;
}
.pfi__form {
    display: block;
    margin-top: 91px;
}
.pfi__title {
    color: #000;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-bottom: 32px;
}
.pfi__form input {
    display: block;
    width: 100%;
    border: none;
    outline-style: none;
    padding-left: 13px;
    padding-right: 13px;
    height: 56px;
    color: #A9A9A9;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    background: #FFF;
    box-shadow: 2px 2px 14px 1px rgba(0, 0, 0, 0.16);
}
.pfi__form input + input {
    margin-top: 10px;
}
.pfi__form .btn_main {
    margin-top: 32px;
}
.btn_main {
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    border: none;
    outline-style: none;
    display: flex;
    background: #C4122F;
    height: 52px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: center;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.btn_main:hover {
    background: #F6CC39;
}


@media screen and (max-width: 880px) {
    .fs_img {
        height: 144px;
    }
    .fs_img img {
        max-height: 144px;
    }
    .flat_slide {
        padding: 25px 20px 30px 20px;
    }
    .pfi__right {
        width: 100%;
        min-width: 20px;
    }
    .pfi__left {
        width: 320px;
        min-width: 320px;
    }
    .pu_flat {
        padding-bottom: 60px;
        padding-left: 40px;
        padding-right: 20px;
    }
    .pfi__form .btn_main {
        margin-top: 20px;
    }
    .pfi__form {
        margin-top: 50px;
    }
}
@media screen and (max-width: 720px) {
    .pu_flat_inner {
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .pfi__right {
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .pfi__left {
        margin-top: 20px;
        width: 100%;
        padding-right: 0;
        min-width: 20px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }
}
@media screen and (max-width: 580px) {
    .head_center {
        display: block;
    }
    .h_btn + .h_btn {
        margin-left: 0 !important;
        margin-top: 5px !important;
    }
    .flat_nav div {
        font-size: 18px;
    }
    .flat_nav div {
        margin-right: 30px;
    }
    .flat_nav div + div {
        margin-right: 22px;
    }
    .flat_nav {
        margin-top: 25px;
    }
    .flat_slider {
        margin-top: 30px;
    }
    .flat {
        padding-bottom: 48px;
    }
}
@media screen and (max-width: 540px) {
    body .toggle-menu {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 420px) {
    .flat {
        padding-left: 20px;
        padding-right: 20px;
    }
    .flat_slider {
        margin-left: -20px;
        margin-right: -20px;
    }
    .flat_nav div {
        font-size: 16px;
    }
    .flat .swiper-button-prev svg, .flat .swiper-button-next svg {
        width: 36px;
    }
    .flat_slider {
        padding-left: 18px;
        padding-right: 18px;
    }
    .pfi__title {
        font-size: 20px;
    }
    .pu_flat {
        padding-left: 15px;
        padding-right: 15px;
    }
    .pu_flat .caption__h2 {
        font-size: 18px;
    }
    .pfi__title {
        padding-bottom: 18px;
    }
    .pfi__form {
        margin-top: 30px;
    }
    .pu_flat_inner {
        margin-top: 13px;
    }
    .pfi__info > li {
        font-size: 18px;
    }
    .pu_flat {
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 360px) {
    .flat_nav {
        margin-right: -22px;
    }
}
/*plan flat and*/

@media screen and (max-width: 1400px) {
    .fs_img {
        height: 180px;
    }

    .fs_img img {
        max-height: 180px;
    }
    .fs_name {
        margin-top: 20px;
    }
    .flat_slide {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .fs_btn {
        margin-top: 15px;
    }
    .flat {
        padding-bottom: 90px;
    }
    .about__bg {
        height: auto;
        width: 100% !important;
    }
}

@media screen and (max-width: 1199px) {
    .header {
        z-index: 200;
        background: rgba(0,0,0,0.4);
    }
}


/* pu akc */
.pu_akc {
    position: relative;
    background: #fff;
    max-width: 1220px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px;
}
.pu_akc__title {
    color: #C4122F;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: normal;
}
.pu_akc__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -12px;
    margin-top: 48px;
}
    .pu_akc__list > li {
        padding-left: 12px;
        box-sizing: border-box;
        width: 33.3333333333%;
    }

    .pu_al_title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        height: 72px;
        background: #2e3744;
        color: #FFF;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 137%;
        letter-spacing: 0.36px;
    }
        .pu_al_title span {
            font-size: 12px;
        }
    
        .pu_akc__list > li img {
            display: block;
            width: 100%;
        }

        .pu_akc__list > li img + .pu_al_title {
            height: 52px;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 18px;
        }
        .pu_about {
            padding: 10px 16px;   
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.01em;
            color: #fff;
            background-color: #c4122f;
            margin-top: 20px;
            text-align: center;
        }

.h_btn {
    cursor: pointer;
    background: #C4122F;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.3s;
}

.h_btn:hover {
    background: #ffc107;
}

.h_btn + .h_btn {
    margin-top: 5px;
}

.pu_akc_inner {
    position: relative;
    max-width: 526px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
    background: #fff;
}

.pu_ai_title {
    color: #FFF;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 78.571% */
    letter-spacing: 0.84px;
    background: #C4122F;
    padding-left: 56px;
    padding-right: 94px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: inline-block;
}

    .pu_ai_title span {
        color: #FFF;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.72px;
        display: block;
        margin-top: 5px;
        display: block;
    }

.pu_ai_form {
    display: block;
    max-width: 326px;
    margin: 0 auto;
    margin-top: 62px;
}

.pu_aif_title {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-bottom: 42px;
}

.pu_ai_form input {
    display: block;
    width: 100%;
    background: #fff;
    border: none;
    padding-left: 13px;
    padding-right: 13px;
    box-sizing: border-box;
    color: #A9A9A9;
    height: 56px;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    box-shadow: 2px 2px 14px 1px rgba(0, 0, 0, 0.16);
}

.pu_ai_form input + input {
    margin-top: 10px;
}

.pu_aif_btn {
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    margin-top: 32px;
    background: #C4122F;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: 0.3s;
}

    .pu_aif_btn:hover {
        background: #F6CC39;
    }

.pu_ipoteka2 {
    position: relative;
    background: #fff;
    max-width: 1264px;
    /* max-width: 910px; */
    margin: 0 auto;
    padding: 64px;
    box-sizing: border-box;
}

.pu_ipoteka_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -12px;
}
    .pu_ipoteka_list > li {
        width: 33.33333333%;
        width: 50%;
        padding-left: 12px;
    }

.pil__inner {
    box-shadow: 0px 1px 16px 2px rgba(0, 0, 0, 0.10);
    padding: 20px;
    padding-top: 0;
}

.pil__title {
    color: #FFF;
    margin-left: -20px;
    margin-right: -20px;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 156.522% */
    letter-spacing: 0.69px;
    background: #E0833C;
    padding-top: 12px;
    padding-bottom: 12px;
}

.pil__info {
    text-align: center;
    margin-top: 28px;
    color: #1F2225;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 156.25% */
    letter-spacing: 0.32px;
}

.pil__info2 {
    color: #1F2225;
    text-align: justify;
    font-family: Montserrat;
    font-size: 8.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 188.235% */
    letter-spacing: 0.17px;
    min-height: 135px;
}

.pil__img {
    height: 220px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
}

    .pil__img img {
        display: block;
        max-width: 100%;
        max-height: 200px;
    }

    .pil__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .pil__btn span {
        display: block;
        cursor: pointer;
        color: #FFF;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.2px;
        background: #C4122F;
        padding: 8px 50px;
        transition: 0.3s;
        padding-bottom: 9px;
    }

    .pil__btn span:hover {
        background: #ffc107;
    }

.pu_ipoteka_inner2 {
    position: relative;
    background: #fff;
    max-width: 923px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
}    

.pii__title {
    color: #FFF;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    letter-spacing: 0.28px;
    background: #E0833C;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 64px;
    padding-right: 114px;
}

.pii__content {
    display: flex;
    align-items: center;
    margin-top: 36px;
    padding-left: 64px;
    padding-right: 64px;
}

.pii__content_l {
    width: 336px;
    min-width: 336px;
}


.pii__content_l .pu_ai_form {
    width: 100%;
    margin-top: 100px;
    max-width: 100%;
}

.pii__info {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.pii__info span {
    display: block;
}

.pii__info span + span {
    margin-top: 8px;
}

.pii__content_r {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 64px;
}

.pii__content_r img {
    display: block;
    width: 100%;
}

@media screen and (max-width: 1300px) {
    .pil__title {
        font-size: 18px;
    }
    .pu_ipoteka2 {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 1080px) {
    .pu_akc {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pu_akc__list {
        margin-top: 28px;
    }
}

@media screen and (max-width: 1020px) {
    .pu_akc__list > li {
        width: 50%;
        margin-bottom: 20px;
    }
    .pu_akc__list {
        flex-wrap: wrap;
        margin-bottom: -20px;
    }
    .pu_akc {
        max-width: 610px;
    }
    .pu_akc__title {
        font-size: 26px;
    }
}

@media screen and (max-width: 980px) {
    .pu_ipoteka_list {
        display: block;
        margin-left: 0;
    }
    .pu_ipoteka_list > li {
        width: 100%;
        padding-left: 0;
    }
    .pu_ipoteka_list > li + li {
        margin-top: 20px;
    }

    .pu_ipoteka {
        max-width: 500px;
    }
    .pil__info2 {
        min-height: 10px;
    }
}

@media screen and (max-width: 820px) {
    .pii__content {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }
    .pii__title {
        padding-left: 30px;
        padding-right: 40px;
    }

    .pii__content_r {
        padding-left: 30px;
    }
    .pii__content_l .pu_ai_form {
        margin-top: 50px;
    }
}

@media screen and (max-width: 720px) {
    .pii__content {
        flex-direction: column;
    }
    .pii__content_l {
        order: 2;
        margin-top: 30px;
    }
    .pii__content_r {
        order: 1;
        padding-left: 0;
        max-width: 500px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 540px) {
    .pu_akc__list > li {
        width: 100%;
    }
    .pu_al_title {
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .pu_akc__list > li img + .pu_al_title {
        height: auto;
    }
}

@media screen and (max-width: 520px) {
    .pu_ai_title {
        padding-left: 34px;
        padding-right: 50px;
    }
    .pu_aif_title {
        font-size: 22px;
    }
    .pu_ai_form {
        margin-top: 25px;
    }
    .pu_aif_title {
        padding-bottom: 25px;
    }

    .pu_ai_title {
        font-size: 22px;
    }

    .pu_ai_title span {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .pu_ipoteka_inner2 {
        padding-bottom: 0px;
    }
    .pii__content_l .pu_ai_form {
        padding-left: 0;
        padding-right: 0;
    }
    .pii__content_l {
        width: 100%;
        min-width: 20px;
    }
    .pii__info {
        font-size: 16px;
    }
    .pii__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 440px) {
    .pu_ai_form {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 420px) {
    .pil__title {
        font-size: 16px;
    }
    .pil__info {
        font-size: 15px;
    }
    .pil__btn span {
        font-size: 18px;
    }
    .pu_akc__title {
        font-size: 20px;
    }
    .pu_akc {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 380px) {
    .pii__title {
        padding-left: 15px;
        padding-right: 20px;
    }
    .pii__content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pu_ai_title {
        font-size: 20px;
        padding-left: 20px;
        padding-right: 30px;
    }

    .pu_aif_title {
        font-size: 18px;
    }

    .pu_ai_form input {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        height: 50px;
    }

    .pu_aif_btn {
        font-size: 16px;
        margin-top: 20px;
    }
    .pu_akc_inner {
        padding-bottom: 30px;
        padding-top: 50px;
    }

    .pu_ai_title span {
        font-size: 18px;
        margin-top: 0;
    }
    .pil__inner {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pil__title {
        margin-left: -10px;
        margin-right: -10px;
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 120%;
    }
    .pil__info {
        font-size: 14px;
    }
}
/* pu akc end */

.header_slider_img img {
    display: block;
    width: 100%;
}

.header_slider_img img + img {
    display: none;
}

.main_auto {
    position: relative;
    height: auto !important;
    min-height: 20px !important;
    z-index: 10;
    background: #C6263E;
    padding-top: 50px;
    padding-left: 30px;
}

.header_slide_btn {
    position: absolute !important;
    display: block;
    width: 50%;
    height: 50%;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 50 !important;
}

.main_auto:before {
    content: "";
    display: block;
    position: absolute;
    width: 80px;
    height: 165px;
    left: 40px;
    top: auto;
    bottom: 10px;
    z-index: 50;
    background: url(../img/urban.png) no-repeat center bottom;
    -webkit-background-size: 100% auto;
         -o-background-size: 100% auto;
            background-size: 100% auto;
}

@media screen and (max-width: 1260px) {
    .main_auto:before {
        left: auto;
        width: 60px;
        bottom: 10px;
        right: 440px;
    }
}

@media screen and (max-width: 1200px) {
    .main_auto {
        padding-left: 0;
    }
    .main_auto {
        padding-top: 20px;
    }
}

@media screen and (max-width: 940px) {
    .main_auto {
        padding-top: 60px;
    }
}

@media screen and (max-width: 780px) {
    .main_auto:before {
        right: 360px;
    }
}

@media screen and (max-width: 560px) {
    .header_slider_img img {
        display: none;
    }

    .header_slider_img img + img {
        display: block;
    }
    .main_auto:before {
        right: auto;
        left: 20px;
    }
    .header_slide_btn {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .main_auto:before {
        width: 40px;
    }
}

/* action */
.action_list {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 98px;
    justify-content: center;
    align-items: flex-start;
    margin-right: -12px;
    margin-left: -12px;
}

.action_list > li {
    flex: 1 0 calc(384px);
    max-width: 580px;
    min-width: 250px;
    margin-bottom: 20px;
    margin-right: 12px;
    margin-left: 12px;
}


.al__img {
    display: block;
    width: 100%;
}

.al__name {
    background-color: #2E3744;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 130%;
}

div.al__name {
    font-size: 20px;
}


.action_main .caption__h2:before {
    background-color: #C4122F;
}

.action_main .container {
    max-width: 1700px;
}

.al__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.al__btn span {
    display: block;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 120%;
    background-color: #C4122F;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.3s;
    width: 100%;
    text-align: center;
}

div.al__btn span {
    padding-left: 50px;
    padding-right: 50px;
}

.al__btn span:hover {
    background-color: var(--brown);
}

@media screen and (max-width: 920px) {
    .al__btn {
        margin-top: 20px;
    }
    .al__name {
        font-size: 18px;
    }
    .action_list {
        margin-top: 38px;
    }
    .al__btn span {
        padding-left: 40px;
        padding-right: 40px;
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 700px) {
    .al__name {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 640px) {
    .action_list {
        display: block;
        margin: 0;
    }

    .action_list > li {
        width: 100%;
        margin: 0;
    }

    .action_list > li + li {
        margin-top: 40px;
        margin-left: 0;
    }
}

/* action end */

/* header timer */
.header_timer_main {
    position: absolute !important;
    z-index: 100;
    width: 506px;
    background: #fff;
    right: 100px;
    bottom: 100px;
    box-sizing: border-box;
    padding: 44px 20px 38px 24px;
    color: #1D3341;
    line-height: 120%;
}
.ht__title {
    font-size: 40px;
    color: #1D3341;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 100%;
}
    .ht__title span {
        display: block;
        margin-top: 8px;
        color: #C92D46;
    }

    .ht__list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 32px;
    }

    .ht__list > li {
        display: block;
        font-size: 24px;
        line-height: 120%;
    }

    .ht__list > li + li {
        margin-top: 24px;
    }
    .ht__list > li span {
        display: inline-block;
        margin-top: 12px;
        color: #fff;
        background-color: #D12C3A;
        padding: 17px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
.header_timer_inner {
    margin-top: 32px;
}
.ht__name {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 120%;
}
.header_timer {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
}
.header_timer > li {
    display: block;
    position: relative;
}
.header_timer > li + li {
    margin-left: 43px;
}

.header_timer > li + li:before, .header_timer > li + li:after {
    content: "";
    display: block;
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #D32E3C;
    left: -27px;
    top: 23px;
}

.header_timer > li + li:after {
    top: 46px;
}
.ht__num {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 80px;
    line-height: 100%;
    text-align: center;
    color: #fff;
    font-size: 56px;
    font-weight: bold;
    background-color: #D32E3C;
}
.header_timer > li span {
    display: block;
    text-align: center;
    margin-top: 8px;
    line-height: 100%;
    font-weight: bold;
    font-size: 28px;
}

.ht__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    justify-content: center;
}

.ht__btn span {
    user-select: none;
    display: inline-flex;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    height: 56px;
    align-items: center;
    justify-content: center;
    justify-content: center;
    background-color: #D32E3C;
    padding-left: 24px;
    padding-right: 24px;
}

@media screen and (max-width: 1600px) {
    .ht__name br {
        display: none;
    }
    .ht__title {
        font-size: 30px;
    }
    .ht__list > li {
        font-size: 20px;
    }
    .ht__list > li span {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 4px;
    }
    .ht__list > li + li {
        margin-top: 18px;
    }
    .ht__name {
        font-size: 23px;
    }
    .ht__num {
        font-size: 34px;
    }
    .ht__num {
        width: 80px;
        height: 60px;
    }
    .header_timer > li span {
        font-size: 17px;
    }
    .header_timer > li + li {
        margin-left: 31px;
    }
    .header_timer > li + li:before, .header_timer > li + li:after {
        width: 8px;
        height: 8px;
        left: -20px;
        top: 19px;
    }

    .header_timer_main {
        width: 386px;
    }

    .header_timer > li + li:after {
        top: 34px;
    }
}

@media screen and (max-width: 1380px) {
    .header_timer_main {
        width: 290px;
        padding: 15px;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .header_timer > li span {
        font-size: 15px;
    }
    .ht__title {
        font-size: 22px;
    }
    .ht__list > li {
        font-size: 16px;
    }
    .ht__list {
        margin-top: 12px;
    }
    .ht__list > li + li {
        margin-top: 14px;
    }
    .ht__name {
        font-size: 16px;
    }
    .ht__num {
        width: 62px;
    }
}

@media screen and (max-width: 1140px) {
    .header_timer_main {
        right: 20px;
    }
}

@media screen and (max-width: 1080px) {
    .ht__list {
        display: flex;
    }
    .ht__list > li {
        width: 50%;
        padding-right: 5px;
        box-sizing: border-box;
    }
    .header_timer_main {
        width: 400px;
    }
    .ht__list > li + li {
        margin-top: 0;
        padding-right: 0;
        padding-left: 5px;
    }
}
@media screen and (max-width: 920px) {
    .ht__btn {
        display: flex;
    }
    .header_slider_img img + img {
        display: block;
    }
    .header_slider_img img {
        display: none;
    }
    .header_timer_main {
        position: static !important;
        width: 100%;
    }

    .main_auto:before, .main_auto .head_bankdom {
        display: none;
    }
    .header_slide_btn {
        width: 100%;
        bottom: 810px;
    }
    .ht__list {
        display: block;
    }

    .ht__list > li {
        width: 100%;
        padding: 0;
    }
    .ht__list > li + li {
        padding: 0;
        margin-top: 24px;
    }
    .ht__title {
        font-size: 32px;
    }
    .ht__list > li {
        font-size: 24px;
    }
    .ht__list > li span {
        padding-top: 15px;
        padding-bottom: 12px;
        margin-top: 6px;
    }
    .ht__name {
        font-size: 24px;
    }

    .ht__name {
        max-width: 400px;
    }
    .ht__num {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 80px;
        line-height: 100%;
        text-align: center;
        color: #fff;
        font-size: 56px;
        font-weight: bold;
        background-color: #2E3844;
    }
    .header_timer > li + li {
        margin-left: 43px;
    }
    .header_timer > li + li:before, .header_timer > li + li:after {
        content: "";
        display: block;
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #2E3844;
        left: -27px;
        top: 23px;
    }
    .header_timer > li + li:after {
        top: 46px;
    }

    .header_timer > li span {
        font-size: 28px;
    }
    .header_timer_main {
        padding: 44px 20px 44px 20px;
        max-width: 490px;
        margin: 0 auto;
    }
    .ht__list {
        margin-top: 26px;
    }
    .main_auto {
        background-color: #fff;
    }
}

@media screen and (max-width: 480px) {
    .ht__num {
        font-size: 32px;
        width: 100%;
    }
    .header_timer > li {
        width: 100%;
    }

    .header_timer > li span {
        font-size: 18px;
    }
    .header_timer > li span {
        font-size: 18px;
        margin-top: 8px;
    }
    .ht__title {
        font-size: 7.6vw;
    }
    .ht__list > li {
        font-size: 20px;
    }
    .ht__name {
        font-size: 20px;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 380px) {
    .ht__btn {
        margin-top: 25px;
    }
    .header_timer > li + li {
        margin-left: 29px;
    }
    .header_timer > li + li:before, .header_timer > li + li:after {
        left: -21px;
    }
    .ht__btn span {
        font-size: 18px;
        height: 62px;
    }
}
/* header timer end */